import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form} from "react-bootstrap";
import "./SignupUser.css";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { AiFillMail } from "react-icons/ai";
import { FaLock, FaUser } from "react-icons/fa";
import { Formik } from "formik";
import * as yup from "yup";
import { register } from "../../actions/auth.js";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Notifications from "../../Components/Notifications/Notifications";
import CustomModal from "../../Components/CustomModal/CustomModal";
import TermsAndConditions from "../../Components/TermsAndConditions/TermsAndConditions";

const SignupUser = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  let history = useHistory();
  //useStates
 
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [notify, setNotify] = useState(false);
  const [modal, setModal] = useState();
  const required = <span style= {{color:"red"}}> * </span>
  // dispatch declaration
  const dispatch = useDispatch();
  const responseMessage = useSelector((state) => state.auth.message);
  const { message } = useSelector((state) => state.message);
  const schema = yup.object({
    userName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  // const handleNotify = (type, message) => {
  //   setNotify(<Notifications type={type} message={message} />);
  // };

  const handleBack = () => {
    history.push("/");
  };
  const handleValues = (values) => {
    dispatch(
      register(values.userName.toLowerCase(), values.email, values.password)
    ).then(() => {
      setNotify(true);
      setNotify(false);
    });
  };
  const handleReadTerms = () => {
    setModal(
      <CustomModal
        bodyType="text"
        size = "lg"
        title="Terms And Conditions"
        text={<TermsAndConditions />}
        show={true}
      />
    );
    // history.push("/TermsAndConditions");
  };
  const handleCheckBox = () => {
    setAcceptTerms(!acceptTerms);
  };

  useEffect(() => {
    if (responseMessage === "Register Successful") {
        history.push("/VerifyMail");
    } else if (responseMessage === "Register Failed") {
       window.location.reload(); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseMessage]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`.navbar {display: none}`}</style>
      </Helmet>
      <Formik
        validationSchema={schema}
        onSubmit={handleValues}
        initialValues={{
          userName: "",
          email: "",
          password: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Container className="SignupContainer">
            <Row xs="12">
              <Col
                xs="8"
                style={{
                  marginTop: "-10%",
                  marginBottom: "15%",
                  marginLeft: "2%",
                }}
              >
                {message && notify && (
                  <Notifications type="error" message={message} />
                )}

                {!message && notify && (
                  <Notifications type="success" message="Register Successful" />
                )}
              </Col>
            </Row>
            <Row xs="1">
              <Col xs="11" md="10" lg="8" xl="8" className="SignupBox">
                {/* <Link to="/"> */}
                <Row className="back-arrow">
                  <IoArrowBackCircleSharp onClick={handleBack} />
                </Row>
                {/* </Link> */}
                <Row xs="1">
                  <Col>
                    <img
                      className="glasses-img"
                      src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717107/Assets/Group_5696_hlaocz.webp"
                      alt="glasses img"
                    />
                  </Col>
                </Row>
                <Row className="form-title">
                  <Col xs="12" lg="7" className="signup-title">
                    SIGN UP
                  </Col>
                </Row>

                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormikUsername"
                  >
                    <Form.Control
                      type="text"
                      placeholder="User Name"
                      className="SignUp-input"
                      name="userName"
                      value={values.userName}
                      onChange={handleChange}
                      isValid={isValid}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      isValid={touched.userName && !errors.userName}
                      isInvalid={!!errors.userName}
                    />
                    <FaUser className="placeholder-icons icon-one" />
                    <Form.Control.Feedback type="invalid">
                      {errors.userName}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="validationFormikemail">
                    <Form.Control
                      type="email"
                      placeholder=" Email"
                      className="SignUp-input"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />
                    <AiFillMail className="placeholder-icons icon-two" />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="validationFormik01">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      className="SignUp-input"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <FaLock className="placeholder-icons icon-three" />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Col xs="10">
                      {" "}
                      
                      <Row xs="1"><Col xs="1"><Form.Check
                      style={{fontSize:"15px",paddingLeft:"1.8rem", paddingRight:"5px"}}
                        custom
                        // label={`Accept Terms and Conditions`}
                        name="all"
                        id="all"
                        onChange={handleCheckBox}
                      /></Col>
                       <Col xs="10" md="10"style={{fontSize: "14px", paddingLeft:"10px",paddingRight:"0" }} >I have read and agreed to the 
                       <Link
                          to="#"
                          style={{ color: "black", fontWeight:"bold"}}
                          onClick={handleReadTerms}
                        >
                        {" "}terms and conditions 
                        </Link>
                       </Col></Row>
                      {/* <Col
                        xs="12"
                        style={{fontSize: "14px", paddingLeft:"2.4rem" }}
                      >
 
                      </Col> */}
                    </Col>
                   {modal && modal}
                  </Row>

                  <Row className="btn-row" xs="1" lg="2">
                    <Col lg="12">
                      <button
                        variant="success"
                        type="submit"
                        className="SignUp-btn"
                        disabled={
                          values.userName === "" ||
                          values.email === "" ||
                          values.password === "" ||
                          errors.useName ||
                          errors.email ||
                          !acceptTerms ||
                          errors.password
                            ? true
                            : false
                        }
                      >
                        continue
                      </button>
                    </Col>
                    {/* <Col><Link to={`/User/${""}`}>Already Registered ?</Link></Col> */}
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        )}
      </Formik>
    </>
  );
};

export default SignupUser;
