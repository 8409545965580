import "./TimedTestPage.css";
import List from "../../Components/List/List";
import { useHistory } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import React, { useState, useEffect } from "react";
import ExamService from "../../utils/ExamService.js";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import SwitchBtnTwo from "../../Components/SwitchBtnTwo/SwitchBtnTwo";
import UserCategoryDisplay from "../../Components/UserCategoryDisplay/UserCategoryDisplay";
import { saveChosenExam } from "../../actions/exam.js";

const TimedTestPage = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  let history = useHistory();
  const dispatch = useDispatch();
  const { examData } = useSelector((state) => state.exam);
  const { user } = useSelector((state) => state.auth);
  let examName = localStorage.getItem("exam");
  //Variables
  let categoryArray = [];
  let categoryArray2 = [];
  let questionsArray = [];
  let questionsToBeSent = [];
  let categoryState = { title: "", state: "" };
  //====================================================================================================//
  //States
  const [state, setState] = useState(false);
  const [listValue, setListValue] = useState(false);
  const [condition, setCondition] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const [categoryList, setCategoryList] = useState();
  const [exam, setExam] = useState();
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState("");
  const [questionsNumber, setQuestionsNumber] = useState();
  const [categoryArrayTwo, setCategoryArrayTwo] = useState([]);
  const [myQuestions, setMyQuestions] = useState([]);
  const [ready, setReady] = useState(false);
  const [text, setText] = useState("Filter your questions below");
  const [timedTest, setTimedTest] = useState(false);
  
  //====================================================================================================//
  //Functions
  const handleSwitchButton = (e) => {
    if (e.target.value === "0") {
      setTimedTest(false);
      setText("Filter your questions below:");
    }
    if (e.target.value === "1") {
      setTimedTest(true);
      setText(`The Duration of the test will automatically default to the
  equivalent amount of time given in the real exam`);
    }
  };
  const handleCheckBox = (e) => {
    setCondition('');
    setTotal(0);
    if (e.target.name === "all") {
      categoryArray = [];
      setListValue(!listValue);
      setCheckAll(e.target.checked);
      if(exam.data){
        for (let i = 0; i < exam.data.length; i++) {
          if(exam.data[i].category){
          categoryArray.push({
            title: exam.data[i].category.title,
            state: e.target.checked,
          });
         }
        }
      }
    } else {
      categoryState = { title: e.target.name, state: e.target.checked };
      categoryArray.push(categoryState);
    }
    setCategoryArrayTwo(categoryArray);
    
  };



  //====================================================================================================//
  const categoryFilter = () => {
    for (let i = 0; i < categoryArrayTwo.length; i++) {
      for (let j = 0; j < categoryArrayTwo.length; j++) {
        if (
          categoryArrayTwo[i].title === categoryArrayTwo[j].title &&
          categoryArrayTwo[i].state !== categoryArrayTwo[j].state &&
          j !== i
        ) {
          categoryArrayTwo[i] = false;
        }
      }
      if (categoryArrayTwo[i] && categoryArrayTwo[i].state) {
        categoryArray2.push(categoryArrayTwo[i]);
      }
    }
   
    
  };

  //====================================================================================================//
  useEffect(() => {
    handleQuestionsFilterList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition])


  const handleQuestionsFilterList = () => {
    categoryFilter();
    if(condition){
    if (condition === "All") {
      var result0 = [];
      if(exam){
        exam.data.forEach(function (item, index) {
          result0[index] = item;
        });
      }
      getQuestions(result0);
    }
    if (condition === "submitted") {
      var result2 = exam.data.filter((itemC) =>
        myQuestions.data.some((itemD) => itemC.id === itemD.questionId)
      );
      
      getQuestions(result2);
    }
    if (condition === "marked") {
      var result3 = exam.data.filter((item1) =>
        myQuestions.data.some(
          (item2) => item1.id === item2.questionId && item2.marked === true
        )
      );
     
      getQuestions(result3);
    }
    if (condition === "new") {
      var result1 = [];
      var found = "notFound";
      for (let i = 0; i < exam.data.length; i++) {
        for (let j = 0; j < myQuestions.data.length; j++) {
          if (exam.data[i].id === myQuestions.data[j].questionId) {
            found = "found";
            break;
          } else found = "notFound";
        }
        if (found === "notFound") {
          result1.push(exam.data[i]);
        }
      }
      
      getQuestions(result1);
     }
    }
  };
  //====================================================================================================//
  const questionFilter = async () => {
    if (!user) {
      history.push("/");
    } else {
      const response = await ExamService.getMyQuestions(user.id);

      setMyQuestions(response);
    }
  };
  //====================================================================================================//
  const getQuestions = (filteredResult) => {
  
    if(categoryArray2 && filteredResult){
    for (let i = 0; i < categoryArray2.length; i++) {
      for (let j = 0; j < filteredResult.length; j++) {
     
        if (filteredResult[j].category &&
          (categoryArray2[i].title.toLowerCase() ===
          filteredResult[j].category.title.toLowerCase())
        ) {
          questionsArray.push(filteredResult[j]);
        }
    
      }
    }
  }
    questionsArray = [...new Set(questionsArray)];
    
    setTotal(questionsArray.length);
    setQuestions(questionsArray);
    if (questionsArray.length > 0) {
      setReady(true);
    } else {
      setReady(false);
    }
  };
  //====================================================================================================//
  const modifyQuestionsArray = () => {
    questionsToBeSent = questions.slice(0, questionsNumber || total);
  };
  const getExamData = async () => {
    //toggling state to allow re-render
    setState(true);
    if (examData) {
      setExam(examData);
      dispatch(saveChosenExam(examData));
    } else {
      const examPlanB = await ExamService.populateExam(examName);
      
      setExam(examPlanB);
      dispatch(saveChosenExam(examPlanB));
    }
  };
  //====================================================================================================//
  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    getExamData();
    questionFilter();
    localStorage.setItem("exam", examName);
    localStorage.removeItem("Score");
    localStorage.removeItem("questionsData");
    // localStorage.removeItem("score");
    localStorage.removeItem("CorrectAnswers");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //====================================================================================================//
  const handleChange = (e) => {
    setQuestionsNumber(e.target.value);
  };
  //====================================================================================================//
  const handleStartQuestions = () => {
    localStorage.setItem("questionsData", JSON.stringify([]));
    localStorage.setItem("first Entry", true);
    localStorage.setItem("Page Number", 1);
    localStorage.setItem("Score", 0);
    localStorage.setItem("CorrectAnswers", 0);

    modifyQuestionsArray();
    questionsToBeSent.sort(() => Math.random() - 0.5);
    if (timedTest) {
      localStorage.setItem("timer", questionsToBeSent.length * 120);
    }
    if (!timedTest) {
      localStorage.setItem("timer", "no timer");
    }
    history.push({
      pathname: "/StartQuestion",
      state: questionsToBeSent,
    });
  };

 

  useEffect(() => {
    if (checkAll) {
      setCategoryList(
        <UserCategoryDisplay
          exam={exam}
          onChange={handleCheckBox}
          isChecked={true}
        />
      );
    } else if (!checkAll) {
      setCategoryList(
        <UserCategoryDisplay exam={exam} onChange={handleCheckBox} />
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exam, checkAll]);
  //====================================================================================================//
  return (
    <Container className="timed-test-container" fluid>
      <Row className="outer-row">
        <Col>
          <Row>
            <Col lg="8" className="timed-test-title">
              <span className="exam-highlight">
                {localStorage.getItem("exam")}
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg="3" className="take-demo-switch-btn">
              <SwitchBtnTwo onClick={handleSwitchButton} />
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="take-demo-category">
              <p className="new-test">{timedTest && "New Timed Test"}</p>
              <Row className="content">
                <Col>
                  {text}
                  <Col>
                    {timedTest && (
                      <img
                        className="timed-test-img"
                        src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717109/Assets/timed_test_icon_vqkeyi.webp"
                        alt="timed test icon"
                      />
                    )}
                  </Col>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          {state && categoryList}

          <Row lg="1" className="questions-selection">
            <Col>
              <Row className="inner-title">Question Selection</Row>
              <hr style={{ width: "110%", marginLeft: "-1.2rem" }} />
              <Row className="question-list">
                <List
                  text0="---Select Questions Status---"
                  text1="Show All questions"
                  text2="Show my new questions only"
                  text3="Show my old questions only"
                  text4="show my marked questions only"
                  value0 = ''
                  value1="All"
                  value2="new"
                  value3="submitted"
                  value4="marked"
                  value = {condition}
                  onChange={(e)=>{setCondition(e.target.value)}}
                  />
              </Row>
            </Col>
          </Row>

          <Row lg="1" className="questions-time">
            <Col>
              <Row className="inner-title-two">
                {`Number of questions for selected categories : ${total}`}
                <br />
                {timedTest &&
                  `Duration Of Exam: ${
                    questionsNumber ? questionsNumber * 2 : total * 2
                  } minutes`}
              </Row>
              <hr style={{ width: " 110%", marginLeft: "-1.2rem" }}></hr>
              <Row className="question-list">
                <List
                  text0="Choose number of questions"
                  value0 = ""
                  value1={questionsNumber}
                  listLength={total}
                  onChange={handleChange}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <button
              className="start-questions"
              onClick={handleStartQuestions}
              disabled={!ready || condition === '' ? true : false}
            >
              Start the Questions &nbsp;&nbsp; <IoIosArrowForward />
            </button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TimedTestPage;
