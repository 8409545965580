import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import "./FileUploader.css";
import { BiUpload } from "react-icons/bi";


const FileUploader = (props) => {


  return (
    <>
    
      <Form.Group>
        <Form.File id={props.id} custom feedbackTooltip  onChange={props.onChange} multiple></Form.File>
        <Form.File.Label
          data-browse="Button text"
          htmlFor={props.id}
          className="uploader-trigger"
        >
          <Row>
            {" "}
            upload image{" "}
            <Col>
              <BiUpload className="upload-icon" style={{cursor:"pointer"}}/>
            </Col>
          </Row>
        </Form.File.Label>
      </Form.Group>
    </>
  );
};

export default FileUploader;
