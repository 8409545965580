import React from "react";
import { Row } from "react-bootstrap";
import "./SwitchBtnTwo.css";
const SwitchBtnTwo = (props) => {
  return (
    <Row className="switchTwo-container">
      <button className="switch-btn2" onClick={props.onClick} value={0}>Questions</button>
      <button className="switch-btn2"onClick={props.onClick}value={1}>Timed Test</button>
    </Row>
  );
};

export default SwitchBtnTwo;
