import { SET_EXAM, SET_QUESTION } from "./types";

export const saveChosenExam = (exam) => (dispatch) => {
  dispatch({
    type: SET_EXAM,
    examData: exam,
  });
};

export const saveQuestionData = (question) => (dispatch) =>{
    dispatch({
        type:SET_QUESTION,
        questionData:question,
    })
}