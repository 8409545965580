/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./ExamCategories.css";


const ExamCategories = (props) => {
  
  let history = useHistory();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    if (user.subscriber === true && user.exam){
      props.history.push("/TimedTest")
    }
    window.scrollTo({ top: 0 });
  }, []);

  const handleClick = async (e) => {
    let examName = e.target.value || e.target.name;
      localStorage.setItem("exam", examName);
        history.push({
          pathname: "/PaymentPage",
          state: examName
        }); 
  };
  return (
    <Container className="exam-categories-container" fluid="xs">
      <Row className="exam-categories-row" xs="1" lg="3" xl="3">
        <button
          className="border-one"
          xs="12"
          value="Internal Medicine"
          onClick={handleClick}
          disabled={user ? false : true }
        >
          <img
            className="image"
            src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,f_auto,q_auto,w_170/v1614717107/Assets/internal_medicine_icon_bgkvri.webp"
            alt="internal medicine img"
            style={{
              width: "30%",
              marginLeft: "0%",
              marginBottom: "2rem",
              marginTop: "0rem",
            }}
            name="Internal Medicine"
          />
          <br />
          Internal Medicine
        </button>
        <button
          className="border-two"
          xs="12"
          value="Surgery"
          onClick={handleClick}
          disabled={user ? false : true }
        >
          <img
            style={{
              width: "20%",
              marginLeft: "0%",
              marginTop: "-1rem",
              marginBottom: "2rem",
            }}
            className="image"
            src="https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717109/Assets/surgery_icon_w9lis2.webp"
            alt="surgery img"
            name="Surgery"
          />
          <br />
          {/* style=
          {{
            textAlign: "center",
            marginTop: "2rem",
            paddingBottom: "2.5rem",
            marginTop: "0.5rem",
          }} */}
          Surgery
        </button>
        <button
          className="border-one"
          xs="12"
          value="Pediatrics"
          onClick={handleClick}
          disabled={user ? false : true }
        >
          <img
            className="image"
            src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_120/v1614717108/Assets/pediatrics_icon_njpl7r.webp"
            alt="Pediatrics img"
            style={{
              width: "30%",
              marginLeft: "0%",
              marginTop: "-1rem",
              marginBottom: "2rem",
            }}
            name="Pediatrics"
          />
          <br />
          Pediatrics
        </button>

        <button
          className="border-two"
          xs="12"
          value="Obstetrics And Gynecology"
          onClick={handleClick}
          disabled={user ? false : true }
        >
          <img
            className="image"
            src="https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717108/Assets/Obstetrics_And_Gynecology_icon_ihibup.webp"
            alt="Obstetrics img"
            style={{
              width: "20%",
              marginBottom: ".5rem",
              marginLeft: "0%",
              marginTop: "-1rem",
            }}
            name="Obstetrics And Gynecology"
          />
          <br />
          Obstetrics And <br /> Gynecolog
        </button>
        <button
          className="border-one"
          xs="12"
          value="Pass Exams"
          onClick={handleClick}
          // disabled={user  || user.exam !== "Full Package" ? false : true }
        >
          <img
            className="image"
            src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_150/v1614717105/Assets/full_package_icon_tbskci.webp"
            alt="SML img"
            style={{
              width: "30%",
              marginBottom: "1.5rem",
              marginLeft: "0%",
              marginTop: "-1rem",
            }}
            name="Pass Exams"
          />
          <br />
          Pass Exams
        </button>
      </Row>
    </Container>
  );
};

export default ExamCategories;
