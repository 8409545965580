import React, { useState,useEffect } from "react";
import { Container, Row, Col, Form} from "react-bootstrap";
import "./AdminLogin.css";
import {Helmet} from "react-helmet"
import { AiFillMail } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { login } from "../../actions/auth.js";
import { useHistory } from "react-router-dom";
import Notifications from "../../Components/Notifications/Notifications";
import PasswordReset from "../../Components/PasswordReset/PasswordReset"

const AdminLogin = (props) => {
 useEffect(() => {
  window.scrollTo({ top: 0 });
 }, [])
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  const [showError, setShowError] = useState({
    inputOne: false,
    inputTwo: false,
  });
  const {isLoggedIn} = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  let history = useHistory();

  const dispatch = useDispatch();

  const schema = yup.object({
    userName: yup.string().required(),
    password: yup.string().required(),
  });
  let emailToken = ":";
  let role = "admin";
  const handleAdminLogin = (values) => {
    
    setLoading(true);
    dispatch(login(values.userName.toLowerCase(), values.password,emailToken, role)).then(() => {
      setNotify(true);
      setNotify(false);
      })
    .catch(() => {

      setLoading(false);
    });
  };
  useEffect(() => {
    if(isLoggedIn === true){
      history.push(`/Dashboard`);
  }
  // else window.location.reload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const handleBlur = (e) => {
    if(e.target.name === "userName")
    {setShowError(prev => prev, showError.inputOne = true)}
    if(e.target.name === "password")
    {setShowError(prev => prev, showError.inputTwo = true)}
  }

  return (<>
    <Helmet><style type="text/css">
      {`.navbar {display: none}`}
    </style></Helmet>
    <Formik
      validationSchema={schema}
      onSubmit={handleAdminLogin}
      initialValues={{
        userName: "",
        password: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        // handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Container className="admin-log-container">
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              height: "800px",
            }}
          >
            {!isLoggedIn && message && notify && (
                <Notifications type= "error" message={message} />
              )} 

              {isLoggedIn && notify && (
             <Notifications type= "success" message="Login Successful" />
              )} 

            
            <Col className="admin-log-box">
              <Row><Col className="icon-col"><img className="admin-icon" src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717101/Assets/adminloginicon_zxtogn.webp" alt="medinice img"/></Col></Row>
             <Row xs="1"><Col className="admin-form-title">ADMIN LOGIN</Col></Row>
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group
                  controlId="validationFormikUsername"
                  className="form-group"
                >
                  <Form.Control
                    type="text"
                    name="userName"
                    placeholder="User Name"
                    className="admin-log-input"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur = {handleBlur}
                    // isValid={isValid}
                    // isValid={touched.userName && !errors.userName}
                    isInvalid={!!errors.userName}
                  />
                  <AiFillMail className="admin-placeholder-icons admin-icon-one" />
                  <Form.Control.Feedback type="invalid" >
                    {showError.inputOne && errors.userName}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="validationFormik01"
                  className="form-group"
                >
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="admin-log-input"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur = {handleBlur}
                    // isValid={touched.password && !errors.password}
                    isInvalid={!!errors.password}
                  />
                  <FaLock className="admin-placeholder-icons admin-icon-three" />
                  <Form.Control.Feedback type="invalid">
                    {showError.inputTwo && errors.password}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
<              Row style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }} >
                <button
                  variant="success"
                  type="submit"
                  className="admin-log-btn"
                  disabled={
                    values.userName === "" ||
                    values.password === "" ||
                    errors.useName ||
                    errors.password
                      ? true
                      : false
                  }
                >
                  continue
                </button>
                
                </Row>
                <Row xs="1" style={{textAlign:"center", marginRight:"4%", marginTop:"4%"}}>
                    <PasswordReset/>
                </Row>

              </Form>
            </Col>
          </Row>
        </Container>
      )}
    </Formik></>
  );
};

export default AdminLogin;
