/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Spinner } from "react-bootstrap";
import Randomstring from "randomstring";

const UserCategoryDisplay = (props) => {
  let exam = props.exam;
  let check = props.isChecked;
  let categoryArray = [];
  let categoryList = [];
  const [showCategory, setShowCategory] = useState([]);

  const getCategories = (exam) => {
    if (exam) {
      for (let i = 0; i < exam.data.length; i++) {
        if(exam.data[i].category){
          categoryArray.push(exam.data[i].category.title);
        }
        
      }
      categoryArray = [...new Set(categoryArray)];
      categoryArray = categoryArray.sort()
      for (let x = 0; x < categoryArray.length; x++) {
        const key = Randomstring.generate(3).toString();
        categoryList.push(
          <Row key = {key}>
            <Col>
              <Form.Check
                custom
                className="category-check"
                label={categoryArray[x]}
                name={categoryArray[x]}
                id={categoryArray[x]}
                onChange={props.onChange}
                checked ={check}
                disabled = {check}
              />
            </Col>
          </Row>
        );
      }
      setShowCategory(categoryList);
    }
  };
  useEffect(() => {
    setShowCategory(<Spinner style={{ marginLeft: "45%" }} animation="border" />)
    getCategories(exam);
  }, [exam,check]);


  return (
    <>
      <Col>
        <img
          className="sticker"
          src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717106/Assets/Group_5693_vjpine.webp"
          alt="exam-category-sticker"
        />
      </Col>
      <Row >
        <Col className="category-box" style={{ marginRight: "73%" }}>
          <Form.Group style={{ marginBottom: "4rem" }} as={Row}>
            <Col key="0.001">
            
            <Form.Check
                custom
                className="category-check"
                label="Select All"
                name="all"
                id="all"
                onChange={props.onChange}
              />
            {showCategory}</Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
export default UserCategoryDisplay;
