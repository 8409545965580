import React from "react";

import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import { HiMenu } from "react-icons/hi";
import {  useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { useHistory } from "react-router-dom";

import "react-pro-sidebar/dist/css/styles.css";

import "./AdminZone.css";
import Icons from "./Icons";


const AdminZone = (props) => {
  let history = useHistory();
  // const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
    history.push("/");
  };
  
    // const [active, setActive] = useState({
    //   item1: true,
    //   item2: false,
    //   item3: false,
    //   item4: false,
    // });
// const userName = user ? (user.username.split(" ").map(part =>{
//     return part[0].toUpperCase() + part.slice(1)
// })) : "Admin";
  return (
<Container fluid className="admin-zone-container" style={{position:"absolute"}}>
<DropdownButton title={<HiMenu style={{fontSize:"16px"}}/>} id="dropdown-menu-align-right" variant = "dark" style={{right:"-10%"}}>
<ProSidebar id="header" style={{ paddingTop:"3px",left:"-147px"}}>
<SidebarContent >
<Menu iconShape="square">
<MenuItem icon = {<Icons  src="/assets/assets home/Dashboard.png"/>} ><Dropdown.Item href="/Dashboard" className ="pro-inner-item " >Dashboard</Dropdown.Item></MenuItem>
<MenuItem icon = {<Icons  src="/assets/assets home/Archive.png"/>}> <Dropdown.Item href="/Archive" className ="pro-inner-item  " >Archive</Dropdown.Item></MenuItem>
<MenuItem icon = {<Icons  src="/assets/assets home/Exam.png"/>}> <Dropdown.Item href="/ClientsPage" className ="pro-inner-item  "  >Exam</Dropdown.Item></MenuItem>
<MenuItem icon = {<Icons  src="/assets/assets home/web.png" width="25px"/>}> <Dropdown.Item href="/UserAccount" className ="pro-inner-item  "  >Account</Dropdown.Item></MenuItem>
</Menu>
</SidebarContent>
<SidebarFooter>
<Menu iconShape="square">
<MenuItem icon={<Icons  src="/assets/assets home/Logout.png"/>} onClick={logOut}> <Dropdown.Item className ="pro-inner-item " >LogOut</Dropdown.Item></MenuItem></Menu></SidebarFooter>
</ProSidebar>
</DropdownButton>
</Container> 

    
  );
};

export default AdminZone;
