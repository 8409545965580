import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import ExamService from "../../utils/ExamService.js";

const ExamDisplay = (props) => {
  let exams;
  let arrayOfExams = [];
  const [showExam, setShowExam] = useState();
  const getExams = async () => {
    exams = await ExamService.getAllExams();
    for (let i = 0; i < exams.data.length; i++) {
      arrayOfExams.push(
        <Row className="client-category-radio" key={i}>
          <Col lg="8">
            <Form.Check
              custom
              type="radio"
              label={exams.data[i].title}
              value={exams.data[i].id}
              name="formHorizontalRadios"
              id={"formHorizontalRadios" + i}
              onClick={props.onClick}
            />
          </Col>
        </Row>
      );
    }
    setShowExam(arrayOfExams);
  };

  useEffect(() => {
    getExams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{showExam}</>;
};

export default ExamDisplay;
