import axios from "axios";
import authHeader from '../services/auth-header';
// const API_URL ="http://localhost:5000";
const API_URL ="";

//Create Exam
const createExam = (data) => {
  return axios.post(API_URL + "/api/exams", data);
};
//Create Category
const createCategory = (data) => {
  return axios.post(API_URL + "/api/categories", data);
};
//Create Question
const createQuestion = (data) => {
  return axios.post(API_URL + "/api/questions", data,{ headers: authHeader() });
};
//get AllExams
const getAllExams = () => {
  return axios.get(API_URL + "/api/exams",{ headers: authHeader() });
};
//getAllCategories byExamId
const getAllCategories = (examId) => {
  return axios.get(API_URL + `/api/categories?examId=${examId}`,{ headers: authHeader() });
};

//getAllQuestions by CategoryId
const getAllQuestions = (categoryId) => {
  return axios.get(
    API_URL +  `/api/questions?categoryId=${categoryId}`,{ headers: authHeader() }
  );
};

//Populate Exam Info by Exam Name
const populateExam = (examName) => {
  return axios.get(
    API_URL + `/api/users/getExamData?examName=${examName}`
  );
};

const getEntireExamsData = () => {
  return axios.get(
    API_URL + `/api/users/getAllExamsData`, { headers: authHeader() }
  )
}
const getNumberOfQuestionsInEachExam = () => {
  return axios.get(
    API_URL + `/api/users/getNumberOfQuestions`
  )
}

//save questions submitted by any user
const submitQuestionDetails = (questionDetails) => {
  return axios.post(
    API_URL + "/api/users/submitQuestions",
    questionDetails , { headers: authHeader() }
  );
};
//get questions that are previously submitted by userId
const getMyQuestions = (userId) => {
  return axios.get(
    API_URL + `/api/users/getSubmittedQuestions?userId=${userId}`,{ headers: authHeader() }
  );
}

// get question by Id on admin's request

const getQuestion = (questionId) => {
  return axios.get(
    API_URL +  `/api/questions/getQuestion?questionId=${questionId}`,{ headers: authHeader() }
  );
}
const editQuestionById = (id,data) => {
  return axios.put(API_URL + `/api/questions/editQuestion/${id}`, data, { headers: authHeader() });
}
const deleteQuestion = (id) => {
  return axios.delete(API_URL + `/api/questions/deleteQuestion/${id}`, { headers: authHeader() });
}

const editCategoryById = (id,data) => {
  return axios.put(API_URL + `/api/categories/editCategory/${id}`, data, { headers: authHeader() });
}
const deleteCategoryById = (categoryId) => {
  return axios.delete(API_URL + `/api/categories/deleteCategory/${categoryId}`, { headers: authHeader() });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createExam,
  createCategory,
  createQuestion,

  getAllExams,
  getAllCategories,
  getAllQuestions,

  populateExam,
  submitQuestionDetails,
  getMyQuestions,

  getQuestion,
  editQuestionById,
  deleteQuestion,

  editCategoryById,
  deleteCategoryById,

  getEntireExamsData,
  getNumberOfQuestionsInEachExam
};
