import React, { useState } from "react";
import {
  Col,
  Container,
  Row
} from "react-bootstrap";
import ExamDisplay from "../../Components/ExamDisplay/ExamDisplay";
import CategoryDisplayList from "../../Components/CategoryDisplayList/CategoryDisplayList";

const Archive = () => {
  const [examId, setExamId] = useState("");

  const handleExamClick = (e) => {
    setExamId(e.target.value);
  };

  return (
    <Container
      style={{ marginTop: "10rem" }}
    >
      <Row>
        <Col style={{ fontSize:"16px", fontWeight:"bold", marginLeft:"4%",  marginBottom:"1%" }}> Choose Exam</Col>
      </Row>
      <Row xs="1"style={{ marginBottom: "3rem" }}>
        <Col xs="11" md="6" lg="6" style={{ boxShadow:"0px 3px 25px #00000029",borderRadius:"10px",padding:"1%", marginLeft:"4%" }}>
          <ExamDisplay onClick={handleExamClick} />
        </Col>
      </Row>
      <Row>
        <Col style={{ fontSize:"16px", fontWeight:"bold", marginLeft:"4%",  marginBottom:"1%" }}> Choose Category</Col>
      </Row>
      <Row xs="1" style={{ marginBottom: "3rem" }}>
        <Col  xs="11" md="6" lg="6" style={{ boxShadow:"0px 3px 25px #00000029",borderRadius:"10px",padding:"1%", marginLeft:"4%"  }}>
          <CategoryDisplayList examId={examId} />
        </Col>
      </Row>
    </Container>
  );
};

export default Archive;
