import React, { useState } from "react";
import AuthService from "../../services/auth.service";
import Notifications from "../../Components/Notifications/Notifications";
import CustomModal from "../CustomModal/CustomModal";
import { Col } from "react-bootstrap";

const PasswordReset = () => {
  const [noteMsg, setNoteMsg] = useState();
  const [modal, setModal] = useState();
  let resetEmail;

  const handleResetPassword = () => {
    setModal(
      <CustomModal
        bodyType="input"
        title="Reset Password"
        text="Enter your email"
        btnText="Reset Password"
        show={true}
        onChange={(e) => handleOnChange(e)}
        onClick={sendResetEmail}
      />
    );
  };
  const handleOnChange = (e) => {
    resetEmail = e.target.value;
  };
  const sendResetEmail = async () => {
    setModal(<CustomModal show={false} />);
    const postEmail = await AuthService.resetPassword(resetEmail);
    
    if (postEmail) {
      setNoteMsg(
        <Notifications
          type="success"
          message={postEmail.data.message}
          position="top-center"
        />
      );
    } else if (!postEmail) {
      setNoteMsg(
        <Notifications
          type="error"
          message={postEmail.data.message}
          position="top-center"
        />
      );
    }
  };

  return (
      <>
      {modal && modal}
      {noteMsg}
    <Col
      style={{
        marginLeft: "0.5rem",
        fontSize: "12px",
        cursor: "pointer",
      }}
      onClick={handleResetPassword}
    >
      Forgot Password?
    </Col>
    </>
  );
};

export default PasswordReset;
