import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  FormControl,
  ListGroup,
} from "react-bootstrap";

const QuestionSearch = ({ questions, examId }) => {
  const [questionList, setQuestionList] = useState();
 
 
  // eslint-disable-next-line no-unused-vars
  const [entered, setEntered] = useState(false);
  const [dirty, setDirty] = useState();
  let arrayList = [];
  const handleSearchQuestion = (e) => {
    setEntered(true);
    if (!e.target.value) {
      setDirty(!dirty);
      return;
    }
    let questionText = e.target.value;
    if (questionText) {
      for (let i = 0; i < questions.length; i++) {
        if (
          questions[i].text.toLowerCase().includes(questionText.toLowerCase())
        ) {
          arrayList.push(
            <Link to={`/QuestionEditor/${questions[i].id}`}>
            <ListGroup.Item
              action
              variant="light"
              value={questions[i].id}
            >
              {questions[i].text}
            </ListGroup.Item></Link>
          );
        }
      }
      setQuestionList(arrayList);
    }
  };

  const showAllQuestions = () => {
    if (!questions) {
      setQuestionList([]);
    } else {
      questions.map((current, index) => {
        return arrayList.push(
          <Link to={`/QuestionEditor/${current.id}`} key={index * 0.01}> <ListGroup.Item action variant="light" key={index * 0.1}>
            {current.text}
          </ListGroup.Item></Link>
        );
      });
      setQuestionList(arrayList);
    }
  };


  useEffect(() => {
    showAllQuestions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, dirty]);

  useEffect(() => {
    setQuestionList([]);
  }, [examId]);

  return (
    <div>
      <Row xs="1" style={{ marginBottom: "3rem" }}>
        <Col xs="12" style={{ width: "100%" }}>
          <Form inline>
            <FormControl
              type="text"
              placeholder="Search For Questions . . ."
              // className="mr-sm-2"
              style={{ width: "100%" }}
              onChange={handleSearchQuestion}
            />
          </Form>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}> Question List </Col>
      </Row>
      {/* <Row> {loading && spinner}</Row> */}
      <Row>
        <Col>
          <ListGroup>{questionList}</ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default QuestionSearch;
