import React, { useState, useEffect } from "react";
import {
  Navbar,
  Form,
  FormControl,
  Button,
  Col,
  Row
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth.js";
import { Formik } from "formik";
import * as yup from "yup";
import "./Navbar.css";
import AuthService from "../../services/auth.service";
import Notifications from "../../Components/Notifications/Notifications";
import PasswordReset from "../../Components/PasswordReset/PasswordReset"

const AppNavbar = (props) => {
  let history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  // let paymentInfo = localStorage.getItem("paymentInfo");
  const dispatch = useDispatch();
  const schema = yup.object({
    userName: yup.string().required(),
    password: yup.string().required(),
  });

  let emailToken = ":";
  const handleLogin = (values) => {
    setLoading(true);
    let role = "user";
    dispatch(
      login(
        values.userName.toLowerCase(),
        values.password.trim(),
        emailToken,
        role
      )
    )
      .then(() => {
        setNotify(true);

        setNotify(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  // const makeAutomaticPayment = async () => {
  //   let paymentInfo = JSON.parse(localStorage.getItem("paymentInfo"));
  //   let data = {
  //     user: user,
  //     examTitle: "",
  //     plan: paymentInfo ? paymentInfo.plan : "",
  //     payment: paymentInfo ? paymentInfo.payment : "",
  //     duration: paymentInfo ? paymentInfo.duration : null,
  //     dueDate: paymentInfo ? paymentInfo.dueDate : null,
  //   };

  //   const pay = await AuthService.paymentRequest(data);

  //   window.location.replace(pay.data.redirect_url);
  //   localStorage.removeItem("paymentInfo");
  // };
  const getUser = async () => {
 
    if (isLoggedIn) {
      const currentUser = await AuthService.getCurrentUser(user);
      if (currentUser.data.role === "admin") {
        history.push("/Dashboard");
      } else if (currentUser.data.role === "user") {
        if (currentUser.data.subscriptionInfo.examName === "") {
          history.push("/ExamCategories");
        } else if (currentUser.data.subscriptionInfo.examName !== "") {
          localStorage.setItem("exam", currentUser.data.subscriptionInfo.examName);
          if (currentUser.data.subscribed) {
            history.push("/TimedTest");
          } else if (!currentUser.data.subscribed) {
            history.push("/PaymentPage");
          }
        }
      }
    }
    else return
  };

  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Navbar expand="lg" className="justify-content-between nav">
      {!isLoggedIn && message && notify && (
        <Notifications type="error" message={message} />
      )}

      {isLoggedIn && notify && (
        <Notifications type="success" message="Login Successful" />
      )}
      <Navbar.Brand href="/">
        <img
          className="saudi-logo"
          src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1614717109/Assets/Saudi_Exams_Logo_rkwtzz.png"
          alt="Saudi Exams Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="nav-toggle"
      />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Formik
          validationSchema={schema}
          onSubmit={handleLogin}
          initialValues={{
            userName: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              inline
              style={{ marginTop: "1rem" }}
              onSubmit={handleSubmit}
              className="nav-form"
            >
              <FormControl
                type="text"
                autoComplete="username"
                placeholder="Username"
                className="mr-sm-4 nav-input"
                name="userName"
                value={values.userName}
                onChange={handleChange}
                isInvalid={!!errors.userName}
              />
              <Form.Control.Feedback
                type="invalid"
                className="error-display"
              ></Form.Control.Feedback>

              <Col style={{ marginTop: "1rem" }}>
                <FormControl
                  type="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  className="mr-sm-4 nav-input"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                <Row xs="12">
                  <PasswordReset/>
                </Row>
              </Col>

              <Form.Control.Feedback
                type="invalid"
                className="error-display"
              ></Form.Control.Feedback>
              <Col className="btn-col-nav">
                <Button
                  variant="success"
                  className="nav-btn login"
                  type="submit"
                  disabled={
                    values.userName === "" ||
                    values.password === "" ||
                    errors.useName ||
                    errors.password
                      ? true
                      : false
                  }
                >
                  Log In
                </Button>

                <Link to="/SignUp">
                  <Button variant="dark" className="nav-btn signup">
                    Sign Up
                  </Button>
                </Link>
              </Col>
            </Form>
          )}
        </Formik>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
