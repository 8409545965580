import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = (props) => {
 
  return (
    <div style={{width:"100%",minWidth:"320px", boxShadow:"0px 3px 25px #00000029",padding:"5px", backgroundColor:"white"}}>
     <Bar data={props.data} options={{ maintainAspectRatio: true }}/>
    </div>
  );
};

export default BarChart;
