/* eslint-disable react-hooks/exhaustive-deps */
import "./StartQuestion.css";
import { GrFlag, GrFlagFill } from "react-icons/gr";
import { CgArrowLeftO } from "react-icons/cg";
import { IoIosArrowDown , IoIosClose, IoIosCheckmark } from "react-icons/io";
import { HiArrowCircleRight } from "react-icons/hi";
import { BiStopwatch } from "react-icons/bi";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import ExamService from "../../utils/ExamService.js";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
const StartQuestion = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const { user } = useSelector((state) => state.auth);

  let history = useHistory();
  if (!user || props.location.state === undefined) {
    // history.push("/")
  }

  //Timer
  let seconds = Number(localStorage.getItem("timer"));
  const [timer, setTimer] = useState(seconds);
  const id = useRef(null);
  const clear = () => {
    clearInterval(id.current);
  };

  // Flag States
  const [markedQuestion, setMarkedQuestion] = useState(false);
  // used to disable inputs after submission
  const [submitted, setSubmitted] = useState(false);
  //used to change the color of the answer based on true oe false answer
  const [answerState, setAnswerState] = useState("");
  // just a toggling state to display first question
  const [state, setState] = useState(false);
  // a state to store the values of the chosen answer
  const [chosenAnswer, setChosenAnswer] = useState({
    choice: "",
    value: "",
  });
  //a state that stores the values to be sent to the data base
  const [questionsData, setQuestionsData] = useState([]);
  // states to show the side list
  const [toggleRef, setToggleRef] = useState(true);
  const [toggleScore, setToggleScore] = useState(false);
  const [showRef, setShowRef] = useState("show-ref");
  //a state to set page number
  let [pageNumber, setPageNumber] = useState(1);
  const [modal, setModal] = useState();
  // eslint-disable-next-line no-unused-vars
  let [pages, setPages] = useState(
    props.location.state ? props.location.state.length : null
  );
  //a state to set score
  const [score, setScore] = useState(0);
  const [scoreData, setScoreData] = useState();
  const [references, setReferences] = useState();
  const [eImageArray, setEImageArray] = useState();
  const [qImageArray, setQImageArray] = useState();
  //a state to set the data of the question to be displayed
  const [question, setQuestion] = useState({
    id: "",
    number: props.location.state
      ? props.location.state.length || localStorage.getItem("questions").length
      : null,
    questImages: [],
    questVideo: "",
    expVideo: "",
    text: "",
    answer: [{}],
    explanation: "",
    lastUpdated: "",
    expImages: [],
    refs: [],
  });
  const [countCorrect, setCountCorrect] = useState(0);
  //===========================================================================================================================================================//
  const refreshStates = () => {
    setAnswerState("");
    setCorrect("");
    setState(!state);
  };
  //===========================================================================================================================================================//
  //a state to show the correct question
  const [correct, setCorrect] = useState({
    One: "",
    Two: "",
    Three: "",
    Four: "",
  });

  const finishExamText = <p>You answered {countCorrect} questions out of {pages} correctly,<br/> 
  You scored: {score}% <br/> {score > 50? "Congrats you passed!": ""} </p> ;
  //===========================================================================================================================================================//
  // function to mark question
  const handleMarkQuestion = () => {
    setMarkedQuestion(!markedQuestion);
  };
  //===========================================================================================================================================================//
  // function to open and close sidebar
  const handleToggle = () => {
    setToggleRef(!toggleRef);
    if (toggleRef) {
      setShowRef("");
    } else setShowRef("show-ref");
  };
  // function to open and close sidebar
  const handleScoreToggle = () => {
    setToggleScore(!toggleScore);

  };
  //===========================================================================================================================================================//
  //function to set new question
  let data = props.location.state;
  const insertQuestionData = (pageNumber) => {
    data &&
      setQuestion(
        (prev) => prev,
        (question.id = data[pageNumber - 1].id),
        (question.questImages = data[pageNumber - 1].questionImgs),
        (question.questVideo = data[pageNumber - 1].questionVideo),
        (question.text = data[pageNumber - 1].text),
        (question.answer = data[pageNumber - 1].answer)
      );
    setQImages(question.questImages);
  };
  const setQImages = (images) => {
    if(images){
      for (let i = 0; i < images.length; i++) {
        if(images[i] !== "" && images[i] !== undefined && images[i] !== null){
        imgQArray.push(
          <Col className="image-col-question" xs="10" lg="10">
            <img
              src={question.questImages[i]}
              alt="questionImage"
              style={{
                width: "100%",
                height: "100%",
                paddingLeft: "0px",
                padding: "1rem",
              }}
            />
          </Col>
        );
      }
    }
    }
    setQImageArray(imgQArray);
  };
  const setEImages = (images) => {
    for (let i = 0; i < images.length; i++) {
      if(images[i] !== "" && images[i] !== undefined && images[i] !== null){
      imgEArray.push(
        <Col className="image-col-question" xs="10" lg="10">
          <img
            src={question.expImages[i]}
            alt="questionImage"
             style={{
              width: "100%",
              height: "100%",
              paddingLeft: "0px",
              padding: "1rem",
            }}
          />
        </Col>
      );
    }
  }
    setEImageArray(imgEArray);
  };

  //===========================================================================================================================================================//
  let Array = [];
  const fillArrayOfQuestionValues = () => {
    values = {
      userId: user ? user.id : null,
      examId: data[pageNumber - 1].category.exam,
      categoryId: data[pageNumber - 1].category._id,
      questionId: data[pageNumber - 1].id,
      question: data[pageNumber - 1].text,
      answer: chosenAnswer,
      marked: markedQuestion,
      submitted: true,
      page: pageNumber
    };
    Array.push(values);
    postQuestionDetails(values);

    setQuestionsData((prev) => [...prev, values]);
    localStorage.setItem("questionsData", JSON.stringify(questionsData));
  };
  //============================================================================================================================================================//
  const checkIfQuestionSubmitted = (cachedQuestions) => {
    // let cachedQuestions = questionsData || JSON.stringify(localStorage.setItem("questionsData"))
    if (cachedQuestions) {
      for (let i = 0; i < cachedQuestions.length; i++) {
        if (cachedQuestions[i].questionId === question.id) {
          setSubmitted(true);
          setChosenAnswer(cachedQuestions[i].answer);
          if (cachedQuestions[i].answer.value === "true") {
            setAnswerState("correct");
          } else {
            setAnswerState("wrong");
          }
          showCorrectAnswer();
          if (cachedQuestions[i].marked === true) {
            setMarkedQuestion(true);
          } else {
            setMarkedQuestion(false);
          }
          setEImages(question.expImages);
          // setRef(question.refs);
        }
      }
    } else {
      setSubmitted(false);
      setAnswerState("");
      setCorrect("");
    }
  };
  //==========================================================================================================================================================//
  //function to move to next page (next question)
  const handleClickNext = () => {
    if (pageNumber < localStorage.getItem("Pages")) {
      setPageNumber(++pageNumber);
      localStorage.setItem("Page Number", pageNumber);
      localStorage.setItem("first Entry", false);
      setAnswerState("");
      setCorrect("");
      
    }
  };
  //============================================================================================================================================================//
  //function to move to previous page (previous question)
  const handleClickBack = () => {
    if (pageNumber > 1) {
      setPageNumber(--pageNumber);
      localStorage.setItem("Page Number", pageNumber);
    }
  };
  // const handleEnableButtons = () => {
  //   if (
  //     (pageNumber === localStorage.getItem("Pages") || pageNumber === pages) &&
  //     submitted === true
  //   ) {
  //     setEnableNavButtons(false);
  //     setExit(
  //       <Link to={user ? "/TimedTest" : "TakeDemo"} className="finish-link">
  //         {" "}
  //         Finish Exam{" "}
  //       </Link>
  //     );
  //   }
  // };
  //============================================================================================================================================================//
  //function to get Score per question
  var total = 0;
  const unitScore = Math.ceil(100 / pages);
  const calculateScore = () => {
    if (chosenAnswer.value === "true") {
      total = score + unitScore;
      const x = total > 100 ? 100: total;
     
      setScore(Number(x));
      setCountCorrect(countCorrect + 1);
      
      localStorage.setItem("CorrectAnswers", Number(countCorrect + 1));
    }
  };
  useEffect(() => {
    localStorage.setItem("Score", score);
  }, [score])

  //=============================================================================================================================================================//
  useEffect(() => {
    if (!pages) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (!pages) {
      history.push("/");
    }
    setTimer(seconds);
    id.current = setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);
  //timer related functions
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time} secs` : `${time} secs`;
  };
  const format = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes} min ${padTime(seconds)}`;
  };

  useEffect(() => {
    localStorage.setItem("timer", timer);
    if (timer === 0) {
      clear();
      history.push("/TimedTest");
    }
  }, [timer]);
  //==============================================================================================================================================================//
  useEffect(() => {
    refreshStates();
    localStorage.setItem("Pages", pages);
    if (localStorage.getItem("Page Number", pageNumber) > 0) {
      let page = localStorage.getItem("Page Number");
      setPageNumber(page);
    } else {
      setPageNumber(1);
    }
    if (
      !localStorage.getItem("questions") ||
      localStorage.getItem("questions" === null)
    ) {
      localStorage.setItem("questions", JSON.stringify(props.location.state));
    }

    setQuestionsData(JSON.parse(localStorage.getItem("questionsData")));
    var cachedQuestions = JSON.parse(localStorage.getItem("questionsData"));
    setTimeout(() => {
      checkIfQuestionSubmitted(cachedQuestions);
    }, 50);
  }, []);
  //===============================================================================================================================================================//
  
  useEffect(() => {
    insertQuestionData(pageNumber);
    setMarkedQuestion(false);
    setSubmitted(false);
    setQuestion(
      (prev) => prev,
      (question.explanation = ""),
      (question.lastUpdated = ""),
      (question.expImages = []),
      (question.expVideo = null)
    );
    setEImages([]);
    // setRef([]);
    setAnswerState("");
    setCorrect("");
    var cachedQuestions = JSON.parse(localStorage.getItem("questionsData"));
    setTimeout(() => {
      checkIfQuestionSubmitted(cachedQuestions);
    }, 50);
  }, [pageNumber]);

  useEffect(() => {
    localStorage.setItem("questionsData", JSON.stringify(questionsData));
    if(submitted && (pageNumber === localStorage.getItem("Pages") || pageNumber === pages)){
      clear();
     user? setModal(
      <CustomModal
        bodyType="text"
        title="Test Finished"
        text= {finishExamText}
        btnText="Exit Exam"
        show={true}
        onClick = {() => {history.push("/TimedTest")}}
        closeBtn = {false}
      />
    ) :     setModal(
        <CustomModal
          bodyType="text"
          title="Demo Test Finished"
          text="We hope you enjoyed having this demo test at Saudi Exam"
          btnText="Register Now"
          show={true}
          onClick = {() => {history.push("/SignUp")}}
          closeBtn = {false}
          btnColor = "success"
        />
      );
    }
  }, [submitted]);

  //================================================================================================================================================================//
 
  // function to set info of the chosen answer
  const handleChosenAnswer = (e) => {
    setChosenAnswer({ value: e.target.value, choice: e.target.id });
  };

  //==================================================================================================================================================================//
  // function that displays the wrong and correct answers
  const showCorrectAnswer = () => {
    for (let i = 0; i < 4; i++) {
      if (data[pageNumber - 1].answer[i][`flag${i + 1}`] === true) {
        if (i === 0) setCorrect({ One: "correct" });
        else if (i === 1) setCorrect({ Two: "correct" });
        else if (i === 2) setCorrect({ Three: "correct" });
        else if (i === 3) setCorrect({ Four: "correct" });
      }
      setQuestion(
        (prev) => prev,
        (question.explanation = data[pageNumber - 1].explanation),
        (question.lastUpdated = data[pageNumber - 1].lastUpdated),
        (question.expImages = data[pageNumber - 1].explanationImgs),
        (question.expVideo = data[pageNumber - 1].explanationVideo),
        (question.refs = data[pageNumber - 1].references)
      );
    }
    setRef(question.refs);
    setEImages(question.expImages);
  };
  let refArray = [];
  let imgQArray = [];
  let imgEArray = [];
  const setRef = (referenceArray) => {
    try {
      for (let i = 0; i < referenceArray.length; i++) {
        if(i === 0){
          refArray.push(<Col style={{ display: "flex", fontWeight: "500" }} className="question-ref">
          References
        </Col> )
        }
        refArray.push(
          <Col style={{ display: "flex", marginLeft:"4%" }}>
            <Link to=""
              target="_blank"
              onClick={() => {
                window.open(referenceArray[i].ref);
              }}
            >
              {referenceArray[i].ref}
            </Link>
          </Col>
        );
      }
      setReferences(refArray);
    } catch (error) {
      console.log(error);
    }
  };
 

  //==================================================================================================================================================================//
  const applyingInterfaceChanges = () => {
    if (chosenAnswer.value === "true") {
      calculateScore();
      localStorage.setItem("Score", score);
      setAnswerState("correct");
    } else if (chosenAnswer.value === "false") {
      setAnswerState("wrong");
    }
    showCorrectAnswer();
  };
  //====================================================================================================================================================================//
  const postQuestionDetails = async (values) => {
    if (user) {
      await ExamService.submitQuestionDetails(values);
    } else return;
  };
  //=====================================================================================================================================================================//
  let values;
  const handleSubmitAnswer = () => {
    applyingInterfaceChanges();
    setSubmitted(true);
    fillArrayOfQuestionValues();
    localStorage.setItem("questionsData", JSON.stringify(questionsData));
     setTimeout(() => {
      handleScoreDisplay();
     }, 0);
    
  };

  const handleScoreDisplay = () => {
    const scoreDisplayArray = [];
    const allSubmittedQuestions = JSON.parse(localStorage.getItem(`questionsData`));
    const questionObj = {};
    for(let i = 0; i < allSubmittedQuestions.length; i++ ){
      questionObj["value"] = allSubmittedQuestions[i].answer.value;
      questionObj["number"] = allSubmittedQuestions[i].page;
      const scoreDisplay = <tr><td>{allSubmittedQuestions[i].page}&emsp;</td><td>{allSubmittedQuestions[i].answer.value === "true"? <IoIosCheckmark style= {{ color:"green"}} /> : <IoIosClose style= {{ color:"red"}}/>}&emsp;</td><td>{allSubmittedQuestions[i].marked ? <GrFlagFill /> : <GrFlag />}</td></tr>
      scoreDisplayArray.push(scoreDisplay);
    }
    setScoreData(scoreDisplayArray)
  }

  window.onbeforeunload = function () {
    history.push("/TimedTest");
  };

  //========================================================================================================================================================================//
  return (
    <Container className="start-question-container" fluid>
      {modal && modal}
      <Row>
        <Col xs="2" className="timer-style">
          {" "}
          <BiStopwatch style={{ fontSize: "35px" }} />{" "}
          {timer ? format(timer) : "no timer"}
        </Col>
      </Row>
      <Row>
        {" "}
        <Col xs="3" className="score-area2">
          <Row>
            <span style={{ marginTop: ".7rem", marginLeft: "35%" }}>
              Score:{score? score: localStorage.getItem("Score")} %
            </span>

            <hr className="hr" />
          </Row>
          <Row style={{ marginTop: "1rem", marginLeft: "15%" }}>
            {" "}
            <table>{scoreData}</table>
            {/* <Col style={{ textAlign: "center" }}>{countCorrect}</Col>
            <Col style={{ textAlign: "center" }}>{pages}</Col> */}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xl="6"
          className="container-col"
          style={{ zIndex: 0, marginLeft: "4rem" }}
        >
          <Row className="control">
            <Col className="control-icons" xs="1" lg="1">
              <CgArrowLeftO
                className=" left"
                onClick={handleClickBack}
              />
            </Col>
            <Col className="question-x-of-y" xs="6" lg="6">
              Question {pageNumber} of {pages}
            </Col>
            <Col className="control-icons" xs="3" lg="3">
              <i onClick={handleMarkQuestion}>
                {markedQuestion ? <GrFlagFill /> : <GrFlag />}
              </i>
            </Col>
            <Col className="control-icons">
              <HiArrowCircleRight
                className="right"
                xs="2"
                onClick={handleClickNext}
              />
            </Col>
          </Row>
          <hr className="hr1" style={{ marginRight: "2rem" }}></hr>
          <Row></Row>

          {question.questVideo && (
            <Row
              style={{ marginLeft: "4%", height: "400px", marginBottom: "4%" }}
            >
              <ReactPlayer
                url={question.questVideo}
                controls
                muted
                width="87%"
                height="100%"
              />
            </Row>
          )}
          <Row>{qImageArray}</Row>
          <Row>
            <Col className="question">{question.text}</Col>
          </Row>

          <fieldset>
            <Form.Group as={Row}>
              <Row className="answer-row" style={{ width: "92%" }}>
                <Form.Check
                  custom
                  type="radio"
                  label={data && data[pageNumber - 1].answer[0].option1}
                  value={data && data[pageNumber - 1].answer[0].flag1}
                  name="choice"
                  id="choice1"
                  className={
                    chosenAnswer.choice === "choice1"
                      ? `answer-choises-two ${answerState}`
                      : `answer-choises-two ${correct.One}`
                  }
                  onChange={handleChosenAnswer}
                  disabled={submitted}
                  checked={submitted === true ? false : undefined}
                />

                <Form.Check
                  custom
                  type="radio"
                  label={data && data[pageNumber - 1].answer[1].option2}
                  value={data && data[pageNumber - 1].answer[1].flag2}
                  name="choice"
                  id="choice2"
                  className={
                    chosenAnswer.choice === "choice2"
                      ? `answer-choises-two ${answerState}`
                      : `answer-choises-two ${correct.Two}`
                  }
                  onChange={handleChosenAnswer}
                  disabled={submitted}
                  checked={submitted === true ? false : undefined}
                />

                <Form.Check
                  custom
                  type="radio"
                  label={data && data[pageNumber - 1].answer[2].option3}
                  value={data && data[pageNumber - 1].answer[2].flag3}
                  name="choice"
                  id="choice3"
                  className={
                    chosenAnswer.choice === "choice3"
                      ? `answer-choises-two ${answerState}`
                      : `answer-choises-two ${correct.Three}`
                  }
                  onChange={handleChosenAnswer}
                  disabled={submitted}
                  checked={submitted === true ? false : undefined}
                />

                <Form.Check
                  custom
                  type="radio"
                  label={data && data[pageNumber - 1].answer[3].option4}
                  value={data && data[pageNumber - 1].answer[3].flag4}
                  name="choice"
                  id="choice4"
                  className={
                    chosenAnswer.choice === "choice4"
                      ? `answer-choises-two ${answerState}`
                      : `answer-choises-two ${correct.Four}`
                  }
                  onChange={handleChosenAnswer}
                  disabled={submitted}
                  checked={submitted === true ? false : undefined}
                />
              </Row>
            </Form.Group>
          </fieldset>
          <Row>
            <Col style={{ marginBottom: "6rem" }}>
              <button
                variant="success"
                type="submit"
                className="submit-answer-btn"
                onClick={handleSubmitAnswer}
                disabled={submitted}
              >
                Submit answer
              </button>
            </Col>
          </Row>

          {submitted &&  <Row>
          <Col className="question-explanation">Explanation:</Col>
          </Row>}
          <Row>
            <Col xs="10" className="explanation">
              {question.explanation}
            </Col>
          </Row>
          {submitted && question.lastUpdated && <Row>
          <Col className="question-explanation">Last Updated:</Col>
          </Row>}
          <Row>
            <Col xs="10" className="explanation">
              {question.lastUpdated}
            </Col>
          </Row>

          {question.expVideo && (
            <Row
              style={{ marginLeft: "4%", height: "400px", marginBottom: "4%" }}
            >
              <ReactPlayer
                url={question.expVideo}
                controls
                muted
                width="87%"
                height="100%"
              />
            </Row>
          )}

      {eImageArray && <Row>{eImageArray} </Row>}
          {submitted  && (
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom:"5%"
              }}
            >
  
              {references}
              
            </Row>
          )}
        </Col>

        <Col className="side-info-col">
          <button onClick={handleToggle} className="ref-toggle-btn">
            Reference Range <IoIosArrowDown />
          </button>

          <Col
            className={"side-info" + showRef}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
            xs="12"
            lg="12"
          >
            <Row className={showRef} xs="1" style={{ marginLeft: "15px" }}>
              <Row className="side-info-title">Full blood count</Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Heamoglobin</Col>
                <Col className="numbers">Men: 135-180g/l Women: 115-160g/l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Mean Cell Volumn</Col>
                <Col className="numbers">82-100fl</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Platelets</Col>
                <Col className="numbers">150-400 * 10 /l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">White Blood Cells</Col>
                <Col className="numbers">4.0-11.0 /l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Nuerophils</Col>
                <Col className="numbers">2.0-7.0 * 10 /l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Lymphocytes</Col>
                <Col className="numbers">1.0-0.3 /l</Col>
              </Row>
              <Row className="side-info-title">Urea and electrolytes</Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Soduim</Col>
                <Col className="numbers">135-145 mmol/l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Potassium</Col>
                <Col className="numbers">3.5-5.0 mmol/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Urea</Col>
                <Col className="numbers">2.0-7 mmol/l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Creatinine</Col>
                <Col className="numbers">55-120 umol/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Biocarbonate</Col>
                <Col className="numbers">22-28 mmol/l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Chloride</Col>
                <Col className="numbers">95-105 mmol/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Biocarbonate</Col>
                <Col className="numbers">22-28 mmol/l</Col>
              </Row>
              <Row className="side-info-title">Liver function tests</Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Bilirubin</Col>
                <Col className="numbers">3-17 mmol/l</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Alanine Transfraes (ALT)</Col>
                <Col className="numbers">95-105 mmol/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Aspartete</Col>
                <Col className="numbers">3-30 ui/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">transaminase (AST)</Col>
                <Col className="numbers"></Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Alkaline Phosphatase (ALP)</Col>
                <Col className="numbers">30-100 umol/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Gamma Glutamyl</Col>
                <Col className="numbers">8-60 u/l</Col>
              </Row>
              <Row className="side-info-row ">
                <Col className="medical-term">Transferase (yGT)</Col>
                <Col className="numbers"></Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Total Protien</Col>
                <Col className="numbers">60-80 g/l</Col>
              </Row>
              <Row className="side-info-title">Other haematology</Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Erythrocyte</Col>
                <Col className="numbers">Men: ( age / 2)</Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">sedimentation</Col>
                <Col className="numbers"> mm/hr Women: </Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">rate ( ESR)</Col>
                <Col className="numbers"> ((age + 10) / 2) mm/hr</Col>
              </Row>
              <Row className="side-info-row">
                <Col className="medical-term">Prothrombin time(PT)</Col>
                <Col className="numbers"> 10-14 secs </Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">
                  Activated patrial thromboplastin time(APTT)
                </Col>
                <Col className="numbers"> 25-35secs</Col>
              </Row>
              <Row className="side-info-row">
                <Col className="medical-term"></Col>
                <Col className="numbers"> </Col>
              </Row>

              <Row className="side-info-row grey">
                <Col className="medical-term">Ferritin</Col>
                <Col className="numbers"> 20-230 ng/l </Col>
              </Row>
              <Row className="side-info-row">
                <Col className="medical-term">Vitamin B12</Col>
                <Col className="numbers"> 200-900 ng/l </Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">Folate</Col>
                <Col className="numbers"> 3.0 nmol/l </Col>
              </Row>
              <Row className="side-info-row">
                <Col className="medical-term">Reticulocytes</Col>
                <Col className="numbers"> 0.5-1.5% </Col>
              </Row>
              <Row className="side-info-row grey">
                <Col className="medical-term">D-Dimer</Col>
                <Col className="numbers"> 400 ng/ml </Col>
              </Row>
            </Row>
          </Col>
        </Col>

        <Col className="score-area" style={{minWidth:"90px"}}>
          <Row onClick={handleScoreToggle} className ="score-area-header" style={{minWidth:"120px"}}>

            <Col xs="9" style={{ marginTop: ".7rem"}}>
            Score:{score? score: localStorage.getItem("Score")} %
            </Col><Col xs="3"style ={{textAlign: "center", marginTop: ".5rem"}}><IoIosArrowDown/></Col>
          </Row>
          {toggleScore &&
            <Row style={{ marginTop: "1rem", paddingBottom:"1rem", justifyContent: "center"}}>
            <table>{scoreData}</table>
          </Row>
          }
          
        </Col>
      </Row>
    </Container>
  );
};

export default StartQuestion;
