import React, { useState, useEffect } from "react";
import { Col, Row, Image, Form, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./MediaContainer.css"

const MediaContainer = (props) => {
  const [ref, setRef] = useState();
  const [questionVideo, setQuestionVideo] = useState();
  const [explanationVideo, setExplanationVideo] = useState();
  const [reference, setReference] = useState();
  const [isArray, setIsArray] = useState(false);
  let refArray = [];
  const displayRefs = (data) => {
    if (props && data) {
      if (Array.isArray(data) && props.type === "reference") {
        setIsArray(true);
        for (let i = 0; i < data.length; i++) {
          if(data[i] !== null){
          refArray.push(
            <Row xs="1" value={data[i] !== null ? data[i].ref: "null"} style={{ width: "100%" , marginTop:".7rem"}} key= {i * 0.1}>
              <Col xs="9" style={{  width: "80%" ,overflow: "hidden"}} key={data[i].ref}>
              {data[i].ref}</Col>
             
           <Col xs="2" key= {i * 0.01} style={{  marginLeft:"8%"}}><Button onClick={() => handleRemoveRef(i)} style={{ marginLeft: "-1rem",width: "2rem", height: "2rem",fontWeight: "600", paddingTop:"1px"}} variant="danger" key= {i * 0.2}>-</Button></Col>  
            </Row>
          );
          }
        }
        setRef(refArray);
      }
    }
  };

  const handleRemoveRef = (i) => {
    let refArrayValues = [];
    delete refArray[i];
    setRef([...refArray], refArray);
    // eslint-disable-next-line array-callback-return
    refArray.map((item) => {
      refArrayValues.push({ref:item.props.value});
    });
    localStorage.setItem("Existing Reference", JSON.stringify(refArrayValues));
  };


  const handleChangeUrl = (e) => {
    if (props.inputId === "questVideo") {
      setQuestionVideo(e.target.value);
      localStorage.setItem("Question Video", e.target.value);
    }
    if (props.inputId === "expVideo") {
      setExplanationVideo(e.target.value);
      localStorage.setItem("explanation Video", e.target.value);
    }
    if (props.inputId === "reference") {
      setReference(e.target.value);
      localStorage.setItem("Existing Reference", e.target.value);
    }
  };

  useEffect(() => {
    displayRefs(props.data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, reference, props.enableEdit]);

  return (
    <>
      {props.data && props.type === "image" && (
        <Row>
          {props.data
            ? props.data.map((img, index) => {
                return (
                  <Col xs={6} md={3} key={img}>
                    <Image
                      src={`${img}`}
                      value={img}
                      thumbnail
                      onClick={props.onClick}
                      className = "thumbnail"
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      )}
      { (
        <>
          <Col xs="12" style={{marginTop:"1rem"}}>
            <ReactPlayer
              url = {
                (!questionVideo && props.inputId === "questVideo"
                  ? props.data
                  : questionVideo) ||
                (!explanationVideo && props.inputId === "expVideo"
                  ? props.data
                  : explanationVideo)
              }
              controls
              muted
              width="100%"
              height="85%"
            />
          </Col></>)}
          {props.type === "videoRef"? <Col xs="12">

<Form.Control
  type="text"
  className="answer-input"
  style={{ height: "auto", width: "100%", minWidth:"100px" }}
  onChange={handleChangeUrl}
  disabled={props.enableEdit ? false : true}
/>
</Col>: ""}
      {isArray && ref}

    </>
  );
};

export default MediaContainer;
