import React,{useEffect} from "react";
import { Container, Row, Col} from "react-bootstrap";
import "../SignupUser/SignupUser.css";
import PaymentCard from "../../Components/PaymentCard/PaymentCard";

const PaymentPage = (props) => {
  const examName = props.location.state || localStorage.getItem('exam');
  useEffect(() => {
    window.scrollTo({ top: 0 });
   }, [])
  return (
    
        <Container style={{marginBottom:"10%"}}>
          { examName !== "Pass Exams" &&
            <Row lg="3" className="ROW-SIX" style={{marginTop:"10rem",marginRight:"5%",marginLeft:"-10%"}}>
          <Col className=" payment-col row7-col" lg="4" >
            <PaymentCard title="BASIC" line1="2 months" line2="100" line3="60" url="border1"/>
          </Col>
          <Col className="payment-col row7-col" lg="4">
            <PaymentCard title="PRO" line1="4 months" line2="140" line3="90" url="border2" />
          </Col>
          <Col className="payment-col row7-col" lg="4">
            <PaymentCard
              title="PREMUIM"
              line1="12 months"
              line2="320"
              line3="280"
              url="border1"
            />
          </Col>
        </Row>
  }
    {examName === "Pass Exams" &&
        <Row lg="3" className="ROW-SIX" style={{marginTop:"2rem",marginRight:"5%",marginLeft:"-10%"}}>
        <Col lg="12" className="row6-col1"><span className="bold-font2 highlight8"></span></Col>
          <Col className="payment-col row7-col" lg="4" >
            <PaymentCard
              title="SMLE-FULL PACKAGE"
              line1="2 months"
              line2="230"
              line3="184"
              url="border4"
            />
          </Col>
          <Col className="payment-col row7-col" lg="4" >
            <PaymentCard
              title="SMLE-FULL PACKAGE"
              line1="4 months"
              line2="350"
              line3="299"
              url="border3"
            />
          </Col>
          <Col className="payment-col row7-col"  lg="4" >
            <PaymentCard
              title="SMLE-FULL PACKAGE"
              line1="12 months"
              line2="850"
              line3="747"
              url="border4"
            />
          </Col>
        </Row>
}
        </Container>
     
  );
};

export default PaymentPage;
