import React from "react";
import { Navbar, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch,useSelector} from "react-redux";
import { logout } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import "../Navbar/Navbar.css";
import AdminZone from "../../Pages/AdminZone/AdminZone";

const AppNavbarTwo = (props) => {
  let history=useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
 
  const logOut = () => {
    dispatch(logout());
    history.push('/')
  };


  const content = <>
   <Navbar.Brand href="/TimedTest">
        <img src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1614717109/Assets/Saudi_Exams_Logo_rkwtzz.png" alt="Saudi Exams Logo" />
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav"  className="nav-toggle" />
     
  <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
  <Link to="/UserAccount"><Button variant="light" className="mr-sm-5 nav-btn2 account-nav ">
    Account
  </Button></Link>
 <Link to="/History"> <Button variant="light" className="mr-sm-5 nav-btn2 history-nav">
    History
  </Button></Link>
    <Button variant="dark" className="mr-sm-5 nav-btn2 logout-btn" onClick={logOut}>
      Log Out
    </Button>
  </Navbar.Collapse></>;


  return (
   
     <Navbar
      expand="lg"
      className="justify-content-between nav2"
    > 

      {user && user.role!=="admin" ? content :  <> <Navbar.Brand href="/TimedTest" >
        <img src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1614717109/Assets/Saudi_Exams_Logo_rkwtzz.png" alt="Saudi Exams Logo" style={{width:"90%"}}/>
        
      </Navbar.Brand> <AdminZone/></> }
      
    </Navbar>)
  
  }

export default AppNavbarTwo;

   
       /* <><Link to="/Dashboard"><Button variant="light" className="mr-sm-5 nav-btn2 account-nav ">
        Dashboard
      </Button></Link>
     <Link to="/Archive"> <Button variant="light" className="mr-sm-5 nav-btn2 history-nav">
        Archive
      </Button></Link>
      <Link to="/ClientsPage"> <Button variant="light" className="mr-sm-5 nav-btn2 history-nav">
        Exam
      </Button></Link>
      <Link to="/UserAccount"> <Button variant="light" className="mr-sm-5 nav-btn2 history-nav">
        Account
      </Button></Link>  */