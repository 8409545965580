import React, { useState, useEffect } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";

const CustomModal = (props) => {
  const [show, setShow] = useState(false);
  const [body, setBody] = useState();
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(props.show);
    if (props.bodyType === "input") {
      setBody(
        <Form.Control
          type="text"
          name="category-title"
          placeholder={props.text}
          className="answer-input"
          onChange={props.onChange}
        />
      );
    }
    if (props.bodyType === "text") {
      setBody(
        <Row>
          <Col>{props.text}</Col>
        </Row>
      );
    }
  }, [props]);

  return (
    <>
      <Modal show={show} size= {props.size? props.size : "md"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
         {props.closeBtn === false ? null  : <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> } 
          {props.btnText && (
            <Button variant={props.btnColor? props.btnColor : "dark"} onClick={props.onClick}>
              {props.btnText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
