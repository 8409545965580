/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector,  useDispatch} from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { saveChosenExam } from "../../actions/exam.js";
import ExamService from "../../utils/ExamService.js";

const PaymentResult = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
   }, [])
  let history = useHistory();
  const [text, setText] = useState("");
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
 const examName = localStorage.getItem('exam');
  useEffect(() => {
    if (!user) {
      history.push("/");
    }
  }, [user]);

  let returnToken = props.match.params.returnToken
    ? props.match.params.returnToken
    : "";
  useEffect(() => {
    // handleReturnUrl();
    validatePayment();
  }, [returnToken]);




  const validatePayment = async () => {
    const validator = await AuthService.queryTransaction({ returnToken });
    try {
      if (
        validator.data.returnToken === "" &&
        validator.data.subscribed === true
      ) {
        setText("Congrats You Are Now An Official Family Member !!");
        let data = {
          user: user,
          examName : examName
        }
       await AuthService.saveUserExam(data).then(async() =>{
       const examData = await ExamService.populateExam(examName);
       dispatch(saveChosenExam(examData));
      localStorage.setItem("exam", examName);
       if (examData) {
        setTimeout(() => {
        history.push({
          pathname: "/TimedTest",
          state: examName
        });
       }, 3000);
      }
    })
      } else if (
        validator.data.returnToken !== "" &&
        validator.data.subscribed === false
      ) {
        setText("Sorry, Your Payment Was Incomplete !!");
        setTimeout(() => {
          history.push({
            pathname: "/PaymentPage",
            state: examName
          });
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container className="exam-categories-container" fluid="xs">
      <Row xs="1">
        <Col
          xs="7"
          className="bold-font5"
          style={{ marginLeft: "30%", marginTop: "20%" }}
        >
          {text}
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentResult;
