import { SET_EXAM, SET_QUESTION } from "../actions/types";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, examData, questionData } = action;

  switch (type) {
    case SET_EXAM:
      return { ...state, examData: examData };
    case SET_QUESTION:
      return { ...state, questionData: questionData };
    default:
      return state;
  }
}
