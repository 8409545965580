import React from "react";
import { Container, Row, Col, Form, Button} from "react-bootstrap";
import {useHistory } from "react-router-dom";
const TermsAndConditions = () => {
  let history = useHistory();
  const handleClick = () => {
    history.push("/SignUp")
  }
  return (
<Container><Row style={{width:"100%", padding:"5%"}}>
      <Col >
     <span style={{fontSize:"25px"}}><b>Terms and Conditions</b></span> <br/><br/>

<b>(1) Introduction</b><br/>

These terms and conditions govern your use of our website (saudiexam.com); by using our website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our website. <br/><br/>


<b>(2) These terms and conditions:</b><br/>

We used a Website Law template created by SEQ Legal to produce these terms and conditions. A wide range of legal documents including distribution contract documents are available from SEQ Legal.<br/><br/>


<b>(3) License to use website:</b><br/>

Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.<br/>

You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.<br/>


You must not:<br/>


(a) Republish material from this website (including republication on another website);<br/>

(b) Sell, rent or sub-license material from the website;<br/>

(c) Show any material from the website in public;<br/>

(d) Reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose;<br/>

(e) Redistribute material from this website except for content specifically and expressly made available for redistribution.<br/>

We do not grant you any other rights in relation to this website or the material on this website. In other words, all other rights are reserved. For the avoidance of doubt, you must not adapt, edit, change, transform, publish, republish, distribute, redistribute, broadcast, rebroadcast or show or play in public this website or the material on this website (in any form or media) without our prior written permission.<br/>


We take the protection of our copyright very seriously. If we discover that you have used our copyright materials in contravention of the license above, we may bring legal proceedings against you seeking monetary damages and an injunction to stop you using those materials. You could also be ordered to pay legal costs.<br/>

If you become aware of any use of our copyright materials that contravenes or may contravene the licence above, please report this by email to admin@saudiexam.com.<br/>

If you become aware of any material on our website that you believe infringes your or any other person's copyright, please report this by email to admin@saudiexam.com. <br/>




<br/><b>(4) Acceptable use:</b><br/>

You must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.<br/>

You must not use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.<br/>

You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our website without our express written consent.<br/>

You must not use our website to transmit or send unsolicited commercial communications.<br/>

You must not use our website for any purposes related to marketing without our express written consent.<br/>


<br/><b>(5) Refunds</b><br/>

All fees are non-refundable once paid, except as expressly otherwise provided in this Agreement.<br/>


<br/><b>(6) Restricted access</b><br/>

Access to certain areas of our website is restricted. We reserve the right to restrict access to other areas of our website, or indeed our whole website, at our discretion.<br/>

If we provide you with a user ID and password to enable you to access restricted areas of our website or other content or services, you must ensure that that user ID and password is kept confidential.<br/>

We may disable your user ID and password in our sole discretion without notice or explanation.<br/>


<br/><b>(7) User generated content</b><br/>


In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to our website, for whatever purpose.<br/>

You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to us the right to sub-license these rights, and the right to bring an action for infringement of these rights.<br/>

Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).<br/>

You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.<br/>

We reserve the right to edit or remove any material submitted to our website, or stored on our servers, or hosted or published upon our website.<br/>

Notwithstanding our rights under these terms and conditions in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on, our website.<br/>



<br/><b>(8) Limited warranties</b><br/>

We do not warrant the completeness or accuracy of the information published on this website; nor do we commit to ensuring that the website remains available or that the material on the website is kept up-to-date.<br/>

To the maximum extent permitted by applicable law we exclude all representations, warranties and conditions relating to this website and the use of this website (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).<br/>




<br/><b>(9) Medical Disclaimer</b><br/>


This website contains general information about medical conditions and treatments. The information is not advice, and should not be treated as such.<br/>

The medical information on this website is provided without any representations or warranties, express or implied. We make no representations or warranties in relation to the medical information on this website.<br/>

Without prejudice to the generality of the foregoing paragraph, we do not warrant that:<br/>


(a) The medical information on this website will be constantly available, or available at all; or<br/>

(b) The medical information on this website is complete, true, accurate, up-to-date, or non-misleading.<br/>


You must not rely on the information on this website as an alternative to medical advice from your doctor or other professional healthcare provider. If you have any specific questions about any medical matter you should consult your doctor or other professional healthcare provider. If you think you may be suffering from any medical condition you should seek immediately medical attention. You should never delay seeking medical advice, disregard medical advice, or discontinue medical treatment because of information on this website.<br/>




<br/><b>(10) Limitations and exclusions of liability</b><br/>

Nothing in these terms and conditions will: <br/>

(a) limit or exclude our or your liability for death or personal injury resulting from negligence; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; (c) limit any of our or your liabilities in any way that is not permitted under applicable law; or (d) exclude any of our or your liabilities that may not be excluded under applicable law.<br/> 

The limitations and exclusions of liability set out in this Section and elsewhere in these terms and conditions: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the terms and conditions or in relation to the subject matter of the terms and conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.<br/> <br/> 


To the extent that the website and the information and services on the website are provided free-of-charge, we will not be liable for any loss or damage of any nature.<br/> 

We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.<br/> 

We will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill.<br/> 

We will not be liable to you in respect of any loss or corruption of any data, database or software.<br/> 

We will not be liable to you in respect of any special, indirect or consequential loss or damage.<br/> 




<br/><b>(11) Indemnity</b><br/>

You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.<br/><br/>



<br/><b>(12) Breaches of these terms and conditions:</b><br/>

Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, we may take such action as we deem appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.<br/>



<br/><b>(13) Variation</b><br/>

We may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of our website from the date of the publication of the revised terms and conditions on our website. Please check this page regularly to ensure you are familiar with the current version.<br/>




<br/><b>(14) Assignment</b><br/>

We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.<br/>

You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.<br/><br/>




<br/><b>(15) Severability</b><br/>

If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.<br/><br/>



<br/><b>(16) Exclusion of third party rights</b><br/>

These terms and conditions are for the benefit of you and us, and are not intended to benefit any third party or be enforceable by any third party. The exercise of our and your rights in relation to these terms and conditions is not subject to the consent of any third party.<br/><br/>



<br/><b>(17) Entire agreement</b><br/>

These terms and conditions, together with our privacy policy, constitute the entire agreement between you and us in relation to your use of our website, and supersede all previous agreements in respect of your use of this website.<br/><br/>



<br/><b>(18) Law and jurisdiction</b><br/>

These terms and conditions will be governed by and construed in accordance with Saudi law, and any disputes relating to these terms and conditions will be subject to the non-exclusive jurisdiction of the courts of Saudi Arabia.<br/><br/>



<br/><b>(19) Our details</b><br/>

The full name of our company is “saudiexam.com" You can contact us by email to  admin@saudiexam.com.<br/><br/>



<br/><b>(20) Registrations and authorizations</b><br/>

We are not registered with any Trade Register, and are not subject to any Authorization Schemes. We do not subscribe to any external codes of conduct.<br/>

    </Col></Row>
</Container>
  );
};

export default TermsAndConditions;
