import React, { useState, useEffect } from "react";
import { Card,Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./PaymentCard.css";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";

const PaymentCard = (props) => {
  let history = useHistory();
  const { user } = useSelector((state) => state.auth);
  // const [text, setText] = useState("Register");
  const [highLightClass, setHighLightClass] = useState("");
  const highLightAssignment = () => {
    switch (props.line3) {
      case "60":
        setHighLightClass("inner-row discount SixteenDollars");
        break;
      case "90":
        setHighLightClass(" inner-row discount TwentyFourDollars");
        break;
      case "280":
        setHighLightClass("inner-row discount SixtyNineDollars");
        break;
      case "184":
        setHighLightClass("inner-row discount FourtyNineDollars");
        break;
      case "299":
        setHighLightClass("inner-row discount SeventyNineDollars");
        break;
      case "747":
        setHighLightClass("inner-row discount HundredNinetyNineDollars");
        break;
      default:
        setHighLightClass("inner-row discount");
    }
  };

  const purchasePackage = async () => {
    let duration = props.line1;
    let d = new Date();
    let year;
    let month;
    let day;
    let dueDate;

    switch (duration) {
      case "2 months":
        day = d.getDate();
        month = d.getMonth() + 2;
        year = d.getFullYear();
        dueDate = new Date(year, month, day);
        break;
      case "4 months":
        day = d.getDate();
        month = d.getMonth() + 4;
        year = d.getFullYear();
        dueDate = new Date(year, month, day);

        break;
      case "12 months":
        day = d.getDate();
        month = d.getMonth();
        year = d.getFullYear() + 1;
        dueDate = new Date(year, month, day);
        break;
      default:
        return 0;
    }
    if (!user) {
      history.push("/SignUp");
    }
    if (user && user.subscriber === false) {
      let data = {
        user: user,
        examTitle: "",
        plan: props.title,
        amount: props.line3,
        duration: duration,
        dueDate: dueDate,
      };
      const pay = await AuthService.paymentRequest(data);

      window.location.replace(pay.data.redirect_url);
    } else {
      localStorage.setItem(
        "paymentInfo",
        JSON.stringify({
          plan: props.title,
          amount: props.line3,
          duration: duration,
          dueDate: dueDate,
        })
      );
    }
  };

  useEffect(() => {
    highLightAssignment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Row>
      <Col className="card-col">
        <Card style={{ width: "20rem" }} className={props.url}>
          <Card.Body>
            <Card.Title className="payment-card-title">
              {props.title}<br/>{props.subtitle}
            </Card.Title>
            {/* <Row> */}
              <Row className="inner-row">{props.line1}</Row>
              <Row className="inner-row cross " lg="12">
                <Col className="crossed-text" xs="8">{props.line2}</Col>
                <Col className="SAR" xs="4">SAR</Col>
                {/* <Col className="dollar">$</Col> */}
              </Row>
              <Row className="announce-discount">discount</Row>
              <Row className={highLightClass}>
                <Col>
                  {props.line3}
                  {/* <span className="dollar bold">$</span> */}
                  <span className="SAR">SAR</span>
                </Col>
              </Row>
            {/* </Row> */}
            <button className="payment-card-btn" onClick={purchasePackage}>
              Buy Package
            </button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentCard;
