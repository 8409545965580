/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import ExamService from "../../utils/ExamService.js";
import AuthService from "../../services/auth.service";
import BarChart from "../../Components/BarChart/BarChart.js";
import DoughnutChart from "../../Components/DoughnutChart/DoughnutChart.js";
import { Col, Container, Row} from "react-bootstrap";
import "../AdminZone/AdminZone.css";
const Dashboard = () => {
  const [barChart, setBarChart] = useState();
  const [doughnutChart, setDoughnutChart] = useState();
  const [doughnutChartUsers, setDoughnutChartUsers] = useState();
  const [barChart2, setBarChart2] = useState();
  let examsData;
  let categories = [];
  let exams = [];
  let activeUsers = [];
  let notActiveUsers = [];
  let notVerifiedUsers = [];
  let users_per_plan = [];
  let durationOne = [];
  let durationTwo = [];
  let durationThree = [];
  let examsSubscribed = [];
  let barData = [];
  let doughnutDataOne = [];

  /**
   * Get All Exam Data
   */
  const getExamsData = async () => {
    examsData = await ExamService.getEntireExamsData();
    getExamStatistics(examsData);
  };

  /**
   * Get All Users Data
   */
  const getAllUsers = async () => {
    const allUsers = await AuthService.getAllUsers();
    handleUserStatistics(allUsers);
  };

  /**
   * Get Exam statistics from retrieved Exams data
   */
  const getExamStatistics = async (examsData) => {
    if (examsData) {
      for (let x = 0; x < examsData.data.length; x++) {
        if (examsData.data[x].category) {
          categories.push(examsData.data[x].category.title);
          exams.push(examsData.data[x].category.exam);
        }
      }
      // eslint-disable-next-line no-unused-vars
      const questions_distribution = categories.reduce(
        (accumulator, current) =>
          Object.assign(accumulator, {
            [current]: (accumulator[current] || 0) + 1,
          }),
        {}
      );
      const categories_distribution = exams.reduce(
        (accumulator, current) =>
          Object.assign(accumulator, {
            [current]: (accumulator[current] || 0) + 1,
          }),
        {}
      );
      const examsInfo = await ExamService.getAllExams();
      for (let i = 0; i < examsInfo.data.length; i++) {
        for (let key in categories_distribution) {
          if (examsInfo.data[i].id === key) {
            let title = examsInfo.data[i].title;
            categories_distribution[title] = categories_distribution[key];
            delete categories_distribution[key];
          }
        }
      }
      let labelNames = [];
      let dataObj = [];
      let datasets = [];
      let colors = ["#787CB5", "#B0B3D6", "#66757F", "#E1E8ED"];
      for (const key in categories_distribution) {
        labelNames.push(key);
        dataObj.push(categories_distribution[key]);
      }

      datasets.push({
        data: dataObj,
        backgroundColor: colors,
      });

      doughnutDataOne = {
        labels: labelNames,
        datasets: datasets,
      };

      var options = {
        plugins: {
          title: {
            display: true,
            text: "Categories / Exam",
            position: "bottom",
          },
        },
      };
      setDoughnutChart(
        <DoughnutChart data={doughnutDataOne} options={options} />
      );
    }
  };
  /**
   * Get User statistics from retrieved Users data
   */
  const handleUserStatistics = (users) => {
    users.data.map((user, index) => {
      if (user.subscribed === true) {
        activeUsers.push(user.username);
        examsSubscribed.push(user.subscriptionInfo.examName);
      } else if (user.subscribed === false) {
        notActiveUsers.push(user);
      }
      if (user.active === false) {
        notVerifiedUsers.push(user);
      }
      if (user.subscribed === true && user.subscriptionInfo.plan !== "") {
        if(user.subscriptionInfo.plan === "SMLE-FULL PACKAGE"){

          if(user.subscriptionInfo.duration === "2 months"){
            durationOne.push("SMLE-FULL PACKAGE (2 months)")
          }
          if(user.subscriptionInfo.duration === "4 months"){
            
            durationTwo.push("SMLE-FULL PACKAGE (4 months)")
          }
          if(user.subscriptionInfo.duration === "12 months"){
            
            durationThree.push("SMLE-FULL PACKAGE (12 months)")
          }
        users_per_plan = users_per_plan.concat(durationOne).concat(durationTwo).concat(durationThree)
      }
      else if(user.subscriptionInfo.plan !== "SMLE-FULL PACKAGE") users_per_plan.push(user.subscriptionInfo.plan)
    }
    
    });
    const subscriptions_distribution = examsSubscribed.reduce(
      (accumulator, current) =>
        Object.assign(accumulator, {
          [current]: (accumulator[current] || 0) + 1,
        }),
      {}
    );
    const plan_distribution = users_per_plan.reduce(
      (accumulator, current) =>
        Object.assign(accumulator, {
          [current]: (accumulator[current] || 0) + 1,
        }),
      {}
    );
    let planNames = [];
    let planNumbers = [];
    for (const key in plan_distribution) {
      planNames.push(key);
      planNumbers.push(plan_distribution[key]);
    }
    let planColors = ["#B0C4DE", "#E6E6FA", "#B0E0E6", "#6495ED", "#4682B4","#4169E1"];
    const usersPlanData = {
      labels: planNames,
      datasets: [
        {
          data: planNumbers,
          backgroundColor: planColors,
        },
      ],
    };
    var planOptions = {
      plugins: {
        title: {
          display: true,
          text: "Subscriptions / Plan",
          position: "bottom",
        },
      },
    };
    setDoughnutChartUsers(
      <DoughnutChart data={usersPlanData} options={planOptions} />
    );

    barData = {
      labels: ["User Statistics"],
      datasets: [
        {
          label: "Subscribers",
          data: [activeUsers.length],
          backgroundColor: ["#004c6d"],
        },
        {
          label: "Not Subscribers",
          data: [notActiveUsers.length],
          backgroundColor: ["#748fa5"],
        },
        {
          label: "Not Verified",
          data: [notVerifiedUsers.length],
          backgroundColor: ["#d0d8e0"],
        },
      ],
    };

    let labelNames = [];
    let dataObj = [];
    let datasets = [];
    let colors = ["#787CB5", "#B0B3D6", "#66757F", "#E1E8ED"];
    for (const key in subscriptions_distribution) {
      labelNames.push(key);
      dataObj.push(subscriptions_distribution[key]);
    }

    datasets.push({
      data: [dataObj],
      backgroundColor: colors,
    });

    let barDataTwo = {
      labels: ["Subscription / Exam"],
      datasets: [
        {
          label: [labelNames[0]],
          data: [dataObj[0]],
          backgroundColor: colors[0],
        },
        {
          label: [labelNames[1]],
          data: [dataObj[1]],
          backgroundColor: colors[1],
        },
        {
          label: [labelNames[2]],
          data: [dataObj[2]],
          backgroundColor: colors[2],
        },
        {
          label: [labelNames[3]],
          data: [dataObj[3]],
          backgroundColor: colors[3],
        },
      ],
    };
    setBarChart(<BarChart data={barDataTwo} />);
    setBarChart2(<BarChart data={barData} />);
  };

  useEffect(() => {
    getExamsData();
    getAllUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      style={{ marginTop: "10rem"}}
      className="admin-zone-container"
      fluid
    >
      <Row  xs="1" className="chart-rows">
        <Col xs="10" lg="5" className="chart-cols"> {barChart2} </Col>
        <Col xs="10" lg="5" className="chart-cols"> {doughnutChartUsers} </Col>
      </Row>
 

      <Row xs="1" className="chart-rows">
        <Col xs="10" lg="5"  className="chart-cols doughnut-two">
          {doughnutChart}
        </Col>  
        <Col xs="10" lg="5"  className="chart-cols">
          {barChart}
        </Col>
      </Row>
      
    </Container>
  );
};

export default Dashboard;
