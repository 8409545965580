import React, { useEffect, useState } from "react";
import { Row, Form, Spinner } from "react-bootstrap";
import ExamService from "../../utils/ExamService.js";
import QuestionSearch from "../QuestionSearch/QuestionSearch";

const CategoryDisplayList = ({
  examId,
  display,
  showDiff, // to show increase in number of questions directly without re-render
}) => {
  let arrayOfCategories = [];
  const [showCategory, setShowCategory] = useState();
  // eslint-disable-next-line no-unused-vars
  const [questNumber, setQuestNumber] = useState();
  const [loading, setLoading] = useState();
  const [questionsOfCategory, setQuestionsOfCategory] = useState();
  const [questionSearchComp, setQuestionSearchComp] = useState();
  // eslint-disable-next-line no-unused-vars
  const [showList, setShowList] = useState(false);
  let categories;
  let questions;
  let sortedCategories = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCategories = async (examId) => {
    setShowCategory();
    categories = await ExamService.getAllCategories(examId);
    sortedCategories = categories.data.sort(function (a, b) {
      let title1 = a.title.toUpperCase();
      let title2 = b.title.toUpperCase();
      if (title1 < title2) {
        return -1;
      }
      if (title1 > title2) {
        return 1;
      }
      return 0;
    });
    arrayOfCategories.push(<option value="" key={0.1}>--- Choose Category ---</option>);
    for (let i = 0; i < sortedCategories.length; i++) {
      questions = await ExamService.getAllQuestions(sortedCategories[i].id);
      setQuestNumber(questions.data.length);
        arrayOfCategories.push(
          <option value={sortedCategories[i].id} key={i * 0.5}>
            {" "}
            {sortedCategories[i].title}{" "}
          </option>
        );
  
    }
    //  setQuestionsArray(questions.data)
    setShowCategory(arrayOfCategories);
  };

  useEffect(() => {
    if (examId) {
      getCategories(examId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, display, showDiff]);

  useEffect(() => {
    if (examId) {
      setLoading(<Spinner style={{ marginLeft: "45%" }} animation="border" />);
      setTimeout(() => {
        setLoading();
      }, 2000);
    }
  }, [examId]);

  const handleCategoryClick = async (e) => {
     let categoryId = e.target.value;
    questions = await ExamService.getAllQuestions(categoryId);
    setQuestionsOfCategory(questions.data);
    setShowList(true);
  };
  useEffect(() => {
    setQuestionSearchComp(
      <QuestionSearch questions={questionsOfCategory} examId={examId} />
    );
    setShowList(false);
  }, [questionsOfCategory, examId]);

  return (
    <>
      <Row> {loading}</Row>
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Category</Form.Label>
            <Form.Control as="select" custom onChange={handleCategoryClick}>
              {showCategory}
            </Form.Control>
          </Form.Group>
        </Form>
        {questionSearchComp}
      </div>
    </>
  );
};

export default CategoryDisplayList;
