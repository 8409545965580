import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form} from "react-bootstrap";
import "./UserLogin.css";
import { FaLock, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { login } from "../../actions/auth.js";
// import { AiFillMail } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Notifications from "../../Components/Notifications/Notifications";
import PasswordReset from "../../Components/PasswordReset/PasswordReset";

const UserLogin = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  let history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  // let paymentInfo = localStorage.getItem("paymentInfo");
  const dispatch = useDispatch();

  const schema = yup.object({
    userName: yup.string().required(),
    password: yup.string().required(),
  });
  let emailToken = props.match.params.token ? props.match.params.token : "";
  const handleLogin = (values) => {
    setLoading(true);
    let role = "user";
    dispatch(
      login(values.userName.toLowerCase(), values.password, emailToken, role)
    )
      .then(() => {
        setNotify(true);
        setNotify(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getUser = async () => {
    if (isLoggedIn) {
      const currentUser = await AuthService.getCurrentUser(user);
      if (currentUser.data.role === "admin") {
        history.push("/Dashboard");
      } else if (currentUser.data.role === "user") {
        if (currentUser.data.subscriptionInfo.examName === "") {
          history.push("/ExamCategories");
        } else if (currentUser.data.subscriptionInfo.examName !== "") {
          localStorage.setItem("exam", currentUser.data.subscriptionInfo.examName);
          if (currentUser.data.subscribed) {
            history.push("/TimedTest");
          } else if (!currentUser.data.subscribed) {
            history.push("/PaymentPage");
          }
        }
      }
    }
    else return
  };

  // const makeAutomaticPayment = async () => {
  //   let paymentInfo = JSON.parse(localStorage.getItem("paymentInfo"));
  //   let data = {
  //     user: user,
  //     examTitle: "",
  //     plan: paymentInfo ? paymentInfo.plan : "",
  //     payment: paymentInfo ? paymentInfo.payment : "",
  //     duration: paymentInfo ? paymentInfo.duration : null,
  //     dueDate: paymentInfo ? paymentInfo.dueDate : null,
  //   };
  //   const pay = await AuthService.paymentRequest(data);
  //   window.location.replace(pay.data.redirect_url);
  //   localStorage.removeItem("paymentInfo");
  // };
  useEffect(() => {
      getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`.navbar {display: none}`}</style>
      </Helmet>
      <Formik
        validationSchema={schema}
        onSubmit={handleLogin}
        initialValues={{
          userName: "",
          password: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Container className="user-log-container">
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                height: "800px",
              }}
            >
              <Col className="user-form-title">USER LOGIN</Col>
              <Col className="user-log-box">
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group
                    className="form-group"
                    controlId="validationFormikUsername"
                  >
                    <Form.Control
                      type="text"
                      placeholder="User Name"
                      className="user-log-input"
                      name="userName"
                      value={values.userName}
                      onChange={handleChange}
                      // isValid={isValid}
                      // isValid={touched.userName && !errors.userName}
                      isInvalid={!!errors.userName}
                    />
                    <FaUser className="admin-placeholder-icons admin-icon-one" />
                    <Form.Control.Feedback type="invalid">
                      {errors.userName}
                    </Form.Control.Feedback>
                    {/* {isLoggedIn && (
                    <Form.Control.Feedback>{message}</Form.Control.Feedback>
                  )} */}
                  </Form.Group>

                  <Form.Group
                    className="form-group"
                    controlId="validationFormik01"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="user-log-input"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      // isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <FaLock className="user-placeholder-icons user-icon-three" />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                    {/* {isLoggedIn && (
                    <Form.Control.Feedback></Form.Control.Feedback>
                  )} */}
                  </Form.Group>

                  <Row lg="12">
                    <Col xs="1"></Col>
                    <Col xs="8">
                      <button
                        variant="success"
                        type="submit"
                        className="user-log-btn"
                        disabled={
                          values.userName === "" ||
                            values.password === "" ||
                            errors.useName ||
                            errors.password
                            ? true
                            : false
                        }
                      >
                        continue
                      </button>
                    </Col>
                    <Col xs="1"></Col>
                  </Row>
                  <Row
                    xs="1"
                    style={{
                      textAlign: "center",
                      marginRight: "4%",
                      marginTop: "4%",
                    }}
                  >
                    <PasswordReset />
                  </Row>
                </Form>
              </Col>
            </Row>
            {!isLoggedIn && message && notify && (
              <Notifications type="error" message={message} />
            )}

            {isLoggedIn && notify && (
              <Notifications type="success" message="Login Successful" />
            )}
          </Container>
        )}
      </Formik>
    </>
  );
};

export default UserLogin;
