import List from "../../Components/List/List";
import { useHistory } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import React, { useState, useEffect } from "react";
import ExamService from "../../utils/ExamService.js";
import { Container, Row, Col } from "react-bootstrap";
import SwitchBtnTwo from "../../Components/SwitchBtnTwo/SwitchBtnTwo";
import UserCategoryDisplay from "../../Components/UserCategoryDisplay/UserCategoryDisplay";
import "./TakeDemo.css";

const TakeDemo = () => {
  let history = useHistory();
  let examName = localStorage.getItem("demoExam");
  //Variables
  let categoryArray = [];
  let categoryArray2 = [];
  let questionsArray = [];
  let questionsToBeSent = [];
  let categoryState = { title: "", state: "" };
  //====================================================================================================//
  //States
  const [state, setState] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [exam, setExam] = useState();
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState("");
  const [questionsNumber, setQuestionsNumber] = useState();
  const [categoryArrayTwo, setCategoryArrayTwo] = useState([]);
  const [current, setCurrent] = useState([]);
  const [ready, setReady] = useState(false);
  const [categoryList, setCategoryList] = useState();
  const [text, setText] = useState("Filter your questions below");
  const [timedTest, setTimedTest] = useState(false);
  //====================================================================================================//
  //Functions
  const handleSwitchButton = (e) => {
    if (e.target.value === "0") {
      setTimedTest(false);
      setText("Filter your questions below:");
    }
    if (e.target.value === "1") {
      setTimedTest(true);
      setText(`The Duration of the test will automatically default to the
      equivalent amount of time given in the real exam.`);
    }
  };
  const handleCheckBox = (e) => {
    if (e.target.name === "all") {
      categoryArray = [];
      setCheckAll(e.target.checked);
      if(exam){
        for (let i = 0; i < exam.data.length; i++) {
          if(exam.data[i].category){
          categoryArray.push({
            title: exam.data[i].category.title,
            state: e.target.checked,
          });
        }
        }
      }
    } else {
      categoryState = { title: e.target.name, state: e.target.checked };
      categoryArray.push(categoryState);
    }
    setCategoryArrayTwo(categoryArray);
    handleQuestionsFilterList("All");
  };
  //====================================================================================================//
  const categoryFilter = () => {
    for (let i = 0; i < categoryArrayTwo.length; i++) {
      for (let j = 0; j < categoryArrayTwo.length; j++) {
        if (
          categoryArrayTwo[i].title === categoryArrayTwo[j].title &&
          categoryArrayTwo[i].state !== categoryArrayTwo[j].state &&
          j !== i
        ) {
          categoryArrayTwo[i] = false;
        }
      }
      if (categoryArrayTwo[i] && categoryArrayTwo[i].state) {
        categoryArray2.push(categoryArrayTwo[i]);
      }
    }
  };
  //====================================================================================================//

  const handleQuestionsFilterList = (e) => {
    let condition = e;
    if (condition === "All") {
      var result0 = [];
      if(exam){
        exam.data.forEach(function (item, index) {
          result0[index] = item;
        });
      }
      setCurrent(result0);
    }
  };

  //====================================================================================================//

  const getQuestions = (filteredResult) => {
    for (let i = 0; i < categoryArray2.length; i++) {
      for (let j = 0; j < filteredResult.length; j++) {
        if (filteredResult[j].category &&
          (categoryArray2[i].title.toLowerCase() ===
          filteredResult[j].category.title.toLowerCase())
        ) {
          questionsArray.push(filteredResult[j]);
        }
      }
    }

    for (let x = 0; x < questionsArray.length; x++) {
      if (x > 1) {
        if (
          questionsArray[x].category.title ===
            questionsArray[x - 1].category.title &&
          questionsArray[x].category.title ===
            questionsArray[x - 2].category.title
        ) {
          questionsArray.splice(x, 1);
          x -= 1;
        }
      }
    }

    setTotal(questionsArray.length);
    setQuestions(questionsArray);
    if (questionsArray.length > 0) {
      setReady(true);
    } else {
      setReady(false);
    }
  };
  //====================================================================================================//
  const modifyQuestionsArray = () => {
    questionsToBeSent = questions.slice(0, questionsNumber || total);
  };

  const getExamData = async () => {
    //toggling state to allow re-render
    setState(true);
    const demoExam = await ExamService.populateExam(examName); 
    setExam(demoExam);
  };

  useEffect(() => {
    if (checkAll) {
      setCategoryList(
        <UserCategoryDisplay
          exam={exam}
          onChange={handleCheckBox}
          isChecked={true}
        />
      );
    } else if (!checkAll) {
      setCategoryList(
        <UserCategoryDisplay exam={exam} onChange={handleCheckBox}/>
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exam, checkAll]);

  //====================================================================================================//

  useEffect(() => {
    categoryFilter();
    getQuestions(current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    getExamData();
    localStorage.setItem("exam", examName);
    localStorage.setItem("demo", false);
    localStorage.removeItem("Score");
    localStorage.removeItem("questionsData");
    // localStorage.removeItem("score");
    localStorage.removeItem("CorrectAnswers");
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //====================================================================================================//
  const handleChange = (e) => {
    setQuestionsNumber(e.target.value);
  };
  //====================================================================================================//
  const handleStartQuestions = () => {
    localStorage.setItem("questionsData", JSON.stringify([]));
    localStorage.setItem("first Entry", true);
    localStorage.setItem("Page Number", 1);
    localStorage.setItem("Score", 0);

    modifyQuestionsArray();
    questionsToBeSent.sort(() => Math.random() - 0.5);
    if (timedTest) {
      localStorage.setItem("timer", questionsToBeSent.length * 120);
    }
    if (!timedTest) {
      localStorage.setItem("timer", "no timer");
    }
    history.push({
      pathname: "/StartQuestion",
      state: questionsToBeSent,
    });
  };
  //====================================================================================================//
  return (
    <Container className="take-demo-container" fluid>
      <Row className="outer-row">
        <Col>
          <Row>
            <Col lg="8" className="take-demo-title">
              <span className="demo-highlight">
                {localStorage.getItem("demoExam")}
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg="3" className="take-demo-switch-btn">
              <SwitchBtnTwo onClick={handleSwitchButton} />
            </Col>
          </Row>
          <Row>
            <Col lg="6" className="take-demo-category">
              <p className="new-test">{timedTest && "New Timed Test"}</p>
              <Row className="content">
                <Col>
                  {text}
                  <Col>
                    {timedTest && (
                      <img
                        className="timed-test-img"
                        src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717109/Assets/timed_test_icon_vqkeyi.webp"
                        alt="take demo icon"
                      />
                    )}
                  </Col>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          {state &&
            // <UserCategoryDisplay exam={exam} onChange={handleCheckBox} />
            categoryList}

          <Row lg="1" className="questions-time">
            <Col>
              <Row className="select-question-title-two">
                {`Number of questions for selected categories : ${total}`}
                <br />
                {timedTest &&
                  `Duration Of Exam: ${
                    questionsNumber ? questionsNumber * 2 : total * 2
                  } minutes`}
              </Row>
              <hr style={{ width: "100%", marginLeft: "0" }}></hr>
              <Row className="question-list">
                <List
                  text1="Choose number of questions"
                  value={questionsNumber}
                  listLength={total}
                  onChange={handleChange}
                />
              </Row>
            </Col>
          </Row>

          {/* <Row lg="1" className="questions-time">
          <Col>
            <Row className="inner-title">{`Duration Of Exam: ${
              total * 2
            } minutes`}</Row>
            <hr style={{ width: " 29.5rem", marginLeft: "-1.2rem" }}></hr>
            <Row className="question-list">
              <Form.Control
                disabled
                type="number"
                placeholder=" time in minutes"
                style={{
                  marginBottom: "1rem",
                  backgroundColor: "transparent",
                }}
              />
            </Row>
          </Col>
        </Row> */}
          <Row>
            <button
              className="start-btn"
              onClick={handleStartQuestions}
              disabled={ready === false ? true : false}
            >
              Start the Questions &nbsp;&nbsp; <IoIosArrowForward />
            </button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TakeDemo;
