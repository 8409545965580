import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "./UserAccount.css";
import { AiFillMail } from "react-icons/ai";
import { FaLock, FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
import Notifications from "../../Components/Notifications/Notifications";

const UserAccount = () => {
  const { user } = useSelector((state) => state.auth);
  const [toggleDisplay, setToggleDisplay] = useState("false");
  // const [status, setStatus] = useState(false);
  // const [message, setMessage] = useState();
  const [notify, setNotify] = useState(false);
  const [subscribe, setSubscribe] = useState("UnSubscribe");
  const [password, setPassword] = useState("");
  let history = useHistory();
  const [displayPasswordInput, setDisplayPasswordInput] = useState("none");
  const [show, setShow] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userId: "",
    userName: "",
    email: "",
  });
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    history.push("/");
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showPasswordInput = () => {
    setToggleDisplay(!toggleDisplay);
    toggleDisplay
      ? setDisplayPasswordInput("block")
      : setDisplayPasswordInput("none");
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    setUserInfo({
      userId: user.id,
      userName: user.username,
      email: user.email,
      role: user.role,
    });
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnsubscribe = async () => {
    const unSubscribe = await AuthService.unSubscribe(user);
    if (unSubscribe) {
      setSubscribe("");
      logOut();
    }
  };
  let currentUser;
  const getUser = async () => {
    currentUser = await AuthService.getCurrentUser(user);
    if (currentUser.data.subscribed) {
      setSubscribe("Unsubscribe");
    } else if (!currentUser.data.subscribed) {
      setSubscribe("");
    }
  };
  useEffect(() => {
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      const postNewPassword = await AuthService.changePassword(
        user.id,
        password
      );
      if (postNewPassword) {
        // setStatus(true);
        // setMessage(postNewPassword.data.message);
        setNotify(<Notifications type ='info' message={postNewPassword.data.message} position = "top-center"/>);
      }   
  };
  return (
    <Container className="account-container">
      {notify}

      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
        xs="1"
      >
        <Col className="account-box">
          <Form>
            <Form.Group
              className="user-acc-form-group"
              controlId="formBasicUser"
            >
              <Form.Control
                type="text"
                className="account-input"
                value={userInfo.userName}
                disabled
                style={{ textTransform: "capitalize" }}
              />
              <FaUser className="holder-icons acc-icon-one" />
            </Form.Group>

            <Form.Group
              className="user-acc-form-group"
              controlId="formBasicEmail"
            >
              <Form.Control
                type="email"
                className="account-input"
                value={userInfo.email}
                disabled
              />
              <AiFillMail className="holder-icons acc-icon-two" />
            </Form.Group>

            <Form.Group
              className="user-acc-form-group"
              controlId="formBasicPassword"
              style={{ display: `${displayPasswordInput}` }}
            >
              <Form.Control
                type="password"
                placeholder="Password"
                className="account-input"
                onChange={handleChangePassword}
                value={password}
              />
              <FaLock className="holder-icons acc-icon-three" />
            </Form.Group>

            <Row className="change-pass">
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={showPasswordInput}
                type="button"
              >
                Change Password
              </button>{" "}
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <button
                variant="success"
                type="submit"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={password ? false : true}
              >
                Submit
              </button>
            </Row>
            <Row>
              <Col xs="2"></Col>
              <Col className="delete-subscription" xs="10"></Col>
            </Row>
          </Form>
        </Col>
        <Col style={{ marginTop: "10%" }}>
          {" "}
          <Link
            to="/UserAccount"
            className="delete-link"
            onClick={handleShow}
            style={{ fontSize: "12px", marginLeft: "40%" }}
          >
            {userInfo.role !== "admin" && subscribe}
          </Link>
          {show && (
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Unsubscribe !</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to unsubscribe?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="danger" onClick={handleUnsubscribe}>
                  {subscribe}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default UserAccount;
