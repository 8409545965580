 //authentication action creator
 import AuthService from '../services/auth.service';
  import {
        REGISTER_SUCCESS,
        REGISTER_FAIL,
        LOGIN_SUCCESS,
        LOGIN_FAIL,
        LOGOUT,
        SET_MESSAGE,
        CLEAR_MESSAGE
      } from "./types";

export const register = (username, email, password) => (dispatch)=>{
    return AuthService.register(username,email,password).then(
    (response)=>{
        dispatch({type:REGISTER_SUCCESS});
        dispatch({
            type:SET_MESSAGE,
            Payload:"Register Successful",
        })

    }).catch((error)=>{
        const message=
            (error.response &&
             error.response.data &&
             error.response.data.message)||
             error.message ||
             error.message||error.toString();

            dispatch({
                type:REGISTER_FAIL,
            });
            dispatch({
                type:SET_MESSAGE,
                payload: message,
            });
      
       })
       
    }



//log In 
export const login = (username , password,emailToken,role) => (dispatch)=>{
    return AuthService.login(username,password,emailToken,role).then(
        (data)=>{
            dispatch({
                type:LOGIN_SUCCESS,
                payload: {
                    user:data
                },
            });
            dispatch({
                type:CLEAR_MESSAGE,
              
            })
           
           
        }).catch(
        (error) => {
            const message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

              dispatch({
                  type:LOGIN_FAIL,
              });
              dispatch({
                  type: SET_MESSAGE,
                  payload: message,
              });
            
        }
    );
};
export const logout = () => (dispatch) =>{
    AuthService.logout();
    dispatch({
        type: LOGOUT,
    })
}   

