import React, { useEffect, useState } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import ExamService from "../../utils/ExamService.js";
import { MdModeEdit, MdDelete } from "react-icons/md";
import CustomModal from "../CustomModal/CustomModal";

const CategoryDisplay = ({ examId, display, onClick, showDiff }) => {
  let arrayOfCategories = [];
  const [showCategory, setShowCategory] = useState();
  const [modal, setModal] = useState();

  let categories;
  let questions;
  let sortedCategories = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCategories = async (examId) => {
    categories = await ExamService.getAllCategories(examId);
    sortedCategories = categories.data.sort(function (a, b) {
      let title1 = a.title.toUpperCase();
      let title2 = b.title.toUpperCase();
      if (title1 < title2) {
        return -1;
      }
      if (title1 > title2) {
        return 1;
      }
      return 0;
    });
    for (let i = 0; i < sortedCategories.length; i++) {
      questions = await ExamService.getAllQuestions(sortedCategories[i].id);
      arrayOfCategories.push(
        <Row className="client-category-radio" key={i * 0.5}>
          <Col xs="8">
            <Form.Check
              custom
              type="radio"
              className= "category-check"
              label={sortedCategories[i].title}
              value={sortedCategories[i].id}
              name="formRadios"
              id={"formRadios" + i + 0.05}
              onClick={onClick}
            />
          </Col>
          <Col xs="1">
            <MdModeEdit
              // eslint-disable-next-line no-loop-func
              onClick={() => showEditModal(sortedCategories[i].id, "input")}
            />
          </Col>
          <Col xs="1">
            <MdDelete
              // eslint-disable-next-line no-loop-func
              onClick={() => showDeleteModal(sortedCategories[i].id, "text", sortedCategories[i].title)}
            />
          </Col>
          <Col className="client-category-tracker" xs="1">
            {questions.data.length}
          </Col>
        </Row>
      );
    }

    setShowCategory(arrayOfCategories);
  };

  useEffect(() => {
    if (examId) {
      getCategories(examId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, display, showDiff]);

  useEffect(() => {
    if (examId) {
      setShowCategory(
        <Spinner style={{ marginLeft: "45%" }} animation="border" />
      );
    }
  }, [examId]);

  const showEditModal = (categoryId, type) => {
    setModal(
      <CustomModal
        bodyType={type}
        title="Edit Category"
        text="Enter New Category Title"
        btnText="Save Changes"
        show={true}
        onChange={(e) => handleOnChange(e)}
        onClick={() => handleEditCategory(categoryId)}
      />
    );
  };
  const showDeleteModal = (categoryId, type, title) => {
    setModal(
      <CustomModal
        bodyType={type}
        title="Delete Category"
        text={["Are you sure you want to delete",<b> {title} </b>, "category?"]}
        btnText="Delete"
        show={true}
        onChange={(e) => handleOnChange(e)}
        onClick={() => handleDeleteCategory(categoryId)}
      />
    );
  };

  let title;
  const handleOnChange = (e) => {
    title = e.target.value;
  };

  const handleEditCategory = async (id) => {
    let data = {
      title: title,
    };
    const editedCategory = await ExamService.editCategoryById(id, data);
    if (editedCategory.status === 200) {
      setModal(<CustomModal show = {false} />);
      setShowCategory(
        <Spinner style={{ marginLeft: "45%" }} animation="border" />
      );
      arrayOfCategories = [];
      sortedCategories = [];
      getCategories(examId);
    }
  };

  const handleDeleteCategory = async (CategoryId) => {
    const DeletedCategory = await ExamService.deleteCategoryById(CategoryId);
    if (DeletedCategory.status === 200) {
      setModal(<CustomModal show = {false} />);
      setShowCategory(
        <Spinner style={{ marginLeft: "45%" }} animation="border" />
      );
      arrayOfCategories = [];
      sortedCategories = [];
      getCategories(examId);
    }
  };

  return (
    <>
      {modal && modal}
      {showCategory}
    </>
  );
};

export default CategoryDisplay;
