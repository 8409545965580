import React from 'react'

const Icons = (props) => {
    return (
        <div>
            <img style={{width:`${props.width? props.width: "45px"}`}} src={props.src}  alt="icon"/>
        </div>
    )
}

export default Icons
