/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./Home2.css";
import { useSelector } from "react-redux";
import AuthService from "../../services/auth.service";
import ExamService from "../../utils/ExamService.js";
const Footer = lazy(() => import("../../Components/Footer/Footer"));
const Card = lazy(() => import("../../Components/Card/Card"));
const PaymentCard = lazy(() => import("../../Components/PaymentCard/PaymentCard"));
const ContactUsForm = lazy(() => import("../../Components/ContactUsForm/ContactUsForm"));
const AboutUsArea = lazy(() => import("../../Components/AboutUsArea/AboutUsArea"));

const Home2 = () => {
const [examQuestions, setExamQuestions] = useState();
const [totalQuestionNum, setTotalQuestionNum] = useState()

  const getNumberOfQuestionPerExam = async () => {
    let sum = 0;
    try{
      const exam = await ExamService.getNumberOfQuestionsInEachExam();
      setExamQuestions(exam);
      for (const key in exam.data){
        sum += Number(exam.data[key]);
      }
      setTotalQuestionNum(sum);
     } catch(err) {
      console.log("ERROR:", err)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    getNumberOfQuestionPerExam();
   }, [])
  const { user,isLoggedIn } = useSelector((state) => state.auth);
  let history = useHistory();
  const handleGoToSection = (id) => {
    document.getElementById(id).scrollIntoView();
  };

  const handleGoToPage = () => {
    history.push("/SignUp");
  };
  const getUser = async () => {
    if (isLoggedIn) {
      const currentUser = await AuthService.getCurrentUser(user);
      if (currentUser.data.role === "admin") {
        history.push("/Dashboard");
      } else if (currentUser.data.role === "user") {
        if (currentUser.data.subscriptionInfo.examName === "") {
          history.push("/ExamCategories");
        } else if (currentUser.data.subscriptionInfo.examName !== "") {
          localStorage.setItem("exam", currentUser.data.subscriptionInfo.examName);
          if (currentUser.data.subscribed) {
            history.push("/TimedTest");
          } else if (!currentUser.data.subscribed) {
            history.push("/PaymentPage");
          }
        }
      }
    }
    else return
  };

  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 0);
  }, [isLoggedIn]);
  
  return (<>
    <Container
      className="home-page-container"
      fluid
      style={{ maxWidth: "2000px" }}
    >
      <Suspense fallback={<div>Loading...</div>}>
      <Row className="MAIN">
        <Row className="ROW-ONE">
          <Col>
            <Col className="intro">
              <br />
              <span className="bold-font">
                PASSING TO THE NEXT MEDICAL LEVEL JUST GOT EASIER !
              </span>
              <br />
              <span className="regular-font">
                We will give you the online service needed that helps you pass
                the highest-level exam and get started! We accelerate your path
                to a bright future...
              </span>
              <br />
            </Col>
            <img
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514361/Assets/1_feplnc.webp"
              alt="#"
              className="intro-arrow"
            />

            <br />
            <Col className="btn-col">
              <button
                className=" action-btn white"
                onClick={() => {
                  handleGoToSection("exam");
                }}
              >
                Find Out More{" "}
              </button>
              <button className=" action-btn orange" onClick={handleGoToPage}>
                Get Started
              </button>
            </Col>
          </Col>

          <Col lg="7">
            <img
              className="doc-img"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/v1614717107/Assets/image_main_c7n30b.png"
              alt="doctor"
            />
            <img
              className="doc-img-croped"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1614717108/Assets/image_main_croped_mxob9m.webp"
              alt="doctor"
            />
          </Col>
        </Row>
        <Row className="ROW-TWO">
          <Col className="row2-col1">
            <img
              style={{ marginLeft: "1.3rem" }}
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_350/v1614717109/Assets/syring_xjrbow.png"
              alt="#"
              className="syring-img"
            />
            <img
              className="three-pages-img "
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_650/v1614717101/Assets/3_papers_o5ztx2.png"
              alt="#"
            />
          </Col>

          <Col className="row2-col2">
            <Col className="bold-font2 highlight3">
            GET PREPARED FOR YOUR <br/> BRIGHT MEDICAL FUTURE
            </Col>
            <br />
            <Col className="regular-font">
              Saudi Exams website is a proven resource that
              <br className="br" />
              addresses medical professionals in KSA . We help you{" "}
              <br className="br" /> practice online exams and a treasure of
              medical
              <br className="br" /> questions. We guide you to success in a
              simple way!
            </Col>
            <br />
            <Link to="/SignUp">
              {" "}
              <button className=" action-btn2 orange" onClick={handleGoToPage}>
                Yes, I want to sign up now!
              </button>
            </Link>
          </Col>
        </Row>
        <Row className="ROW-THREE">
          <Col className="row3-col1" lg="7">
            <Col className="row3-col1-col1">
              <Col className="highlight">
                <span className="thin-font">
                  {" "}
                  WHAT WILL YOU FIND INSIDE THE
                </span>
                <br />
                <span className="bold-font4"> SAUDI EXAM WEBSITE? </span>{" "}
              </Col>

              <img
                className="arrow2-size"
                src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514361/Assets/4_rt1mjl.png"
                alt="#"
              />
              <br />
              <span className="highlight4 bold-font3">
                {" "}
                You will find several types of exams, each comprising medical
                <br />
                multiple choice questions and answers
              </span>
              <br />
              <span className="regular-font">
                {" "}
                Taking our online exams helps you practice and makes you closer{" "}
                <br className="br" /> to success, and closer to the phrase you
                will here soon: "Thank <br className="br" /> you, dear doctor,
                for making your night and day equal since your
                <br className="br" /> medical exam!"
              </span>
            </Col>
          </Col>
          <Col lg="5">
            <img
              className="category-img"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514361/Assets/2_a8qwnh.webp"
              alt="#"
            />
            <img
              className="category-img-two"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514394/Assets/2_Mobile_jm27de.png"
              alt="#"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row style={{width: "100%", marginLeft:"auto", marginTop:"-8%"}} xs="1" id="exam"> <Col xs="4" md="4"></Col><Col xs="4" md="4"  className="row4-col1 highlight5">Exams</Col><Col xs="4" md="4"></Col></Row>

        <Row className="ROW-FOUR"  xl="3">



          <Col className="border Internal-medicine">
            <img
              className="image"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,f_auto,q_auto,w_170/v1614717107/Assets/internal_medicine_icon_bgkvri.png"
              alt="internal medicine img"
              style={{ width: "35%", marginLeft: "32%" }}
            />
            <Card
              icon = "https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,f_auto,q_auto,w_170/v1614717107/Assets/internal_medicine_icon_bgkvri.png"
              title ={`Internal Medicine`}
              questionNum ={`( ${ examQuestions && examQuestions.data["Internal Medicine"]} MCQ )`}
              text='Question Bank for the Internal Medicine part of the SMLE and Family Medicine'
            />
          </Col>
          <Col className="border Surgery">
            <img
              style={{ width: "20%", marginLeft: "40%" }}
              className="image surgery"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717109/Assets/surgery_icon_w9lis2.png"
              alt="surgery img"
            />
            <Card
              icon = "https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717109/Assets/surgery_icon_w9lis2.png"
              title={`Surgery`}
              questionNum ={`( ${ examQuestions && examQuestions.data["Surgery"]} MCQ )`}
              text='Question Bank for the Surgery part of the SMLE and Family Medicine'
            />
          </Col>
          <Col className="border Pediatrics">
            <img
              className="image"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_120/v1614717108/Assets/pediatrics_icon_njpl7r.png"
              alt="Pediatrics img"
              style={{ width: "28%", marginLeft: "40%" }}
            />
            <Card
              icon = "https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_120/v1614717108/Assets/pediatrics_icon_njpl7r.png"
              title={`Pediatrics`}
              questionNum ={`( ${ examQuestions && examQuestions.data["Pediatrics"]} MCQ )`}
              text='Question Bank for the Pediatric part of the SMLE and Family Medicine'
            />
          </Col>
          <Col className="border Obstetrics">
            <img
              className="image obstetric"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717108/Assets/Obstetrics_And_Gynecology_icon_ihibup.png"
              alt="Obstetrics img"
              style={{
                width: "26%",
                marginBottom: "-1.5rem",
                marginLeft: "33%",
              }}
            />
            <Card
              icon = "https://res.cloudinary.com/hnknwhgxi/image/upload/q_auto/v1614717108/Assets/Obstetrics_And_Gynecology_icon_ihibup.png"
              title={`Obstetrics And Gynecology`}
              questionNum ={`( ${ examQuestions && examQuestions.data["Obstetrics And Gynecology"]} MCQ )`}
              text='Question Bank for the OB/GYN part of the Saudi Medical Licensing Exam and Family Medicine'
            />
          </Col>
          <Col className="border SML">
            {" "}
            <img
              className="image SML-img"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_150/v1614717105/Assets/full_package_icon_tbskci.png"
              alt="SML img"
              style={{
                width: "34%",
                marginBottom: "-.5rem",
                marginLeft: "37%",
              }}
            />
            <Card
              icon = "https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_150/v1614717105/Assets/full_package_icon_tbskci.png"
              title="Pass Exams"
              questionNum = {`( ${totalQuestionNum && totalQuestionNum} MCQ )`}
              text='This question bank focuses on core SMLE, Family Medicine, USMLE-step1, and MRCGP topics'
            />
          </Col>
        </Row>
        <Row className="ROW-FIVE">
          <Col className="row5-col1" lg="6">
            <Col className="bold-font4 highlight7">
              WE DELIVER
              <br /> EXCELLENCE!
            </Col>
            <br />
            <span className="bold-font5">
              Medical education in Saudi Arabia has been changing and developing
              rapidly. Obviously, it is in its crucial phase! And it is also
              your chance to grow ...
            </span>
            <br />
            <span className="regular-font ">
              {" "}
              Getting your prefect dose of medical question, your ideal amount
              of medical information, and your highest quantity/quality of
              exams, makes you improve in your career in KSA.
            </span>
          </Col>
          <Col lg="4">
            <img
              className="choose-question-img"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514361/Assets/3_u9twqd.webp"
              alt="#"
            />
            <img
              className="choose-question-img-two"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1616514394/Assets/3_Mobile_zgnmxh.png"
              alt="#"
            />
          </Col>
        </Row>
        <Row className="ROW-SIX" >
          <Col lg="12" className="row6-col1">
            <span className="bold-font2 highlight6">
              SUBSCRIBE TO ONE OF OUR EXAMS BY SELECTING THE PLAN THAT REFLECTS
              YOUR NEEDS
            </span>
          </Col>
          <Col className=" payment-col row7-col" lg="3">
            <PaymentCard
              title="BASIC"
              line1="2 months"
              line2="100"
              line3="60"
              url="border1"
            />
          </Col>
          <Col className="payment-col row7-col" lg="3">
            <PaymentCard
              title="PRO"
              line1="4 months"
              line2="140"
              line3="90"
              url="border2"
            />
          </Col>
          <Col className="payment-col row7-col" lg="3">
            <PaymentCard
              title="PREMIUM"
              line1="12 months"
              line2="320"
              line3="280"
              url="border1"
            />
          </Col>
        </Row>
        <Row lg="3" className="ROW-SIX">
          <Col lg="12" className="row6-col1">
            <span className="bold-font2 highlight8">
              OR SUBSCRIBE TO OUR EXCLUSIVE FULL PACKAGE BELOW
            </span>
          </Col>
          <Col className="payment-col row7-col" lg="3">
            <PaymentCard
              title="PASS EXAMS"
              subtitle="BASIC"
              line1="2 months"
              line2="230"
              line3="184"
              url="border4"
            />
          </Col>
          <Col className="payment-col row7-col" lg="3">
            <PaymentCard
              title="PASS EXAMS"
              subtitle="PRO"
              line1="4 months"
              line2="350"
              line3="299"
              url="border3"
            />
          </Col>
          <Col className="payment-col row7-col" lg="3">
            <PaymentCard
              title="PASS EXAMS"
              subtitle="PREMIUM"
              line1="12 months"
              line2="850"
              line3="747"
              url="border4"
            />
          </Col>
        </Row>
        <Row className="ROW-SEVEN">
          <Col className="row8-col1" lg="12" xl="6">
            <span className="bold-font4">
              OUR TEAM OF EXPERTS IS
              <br /> DEDICATED TO OFFERING <br /> THE BEST PRACTICING SERVICES
            </span>
            <br />
            <span className="regular-font2 highlight9">
              {" "}
              Your medical journey begins here!
            </span>
            <br />
            <button className="contact-us-btn" onClick={handleGoToPage}>
              Start today!
            </button>
          </Col>
          <Col className="row8-col2" lg="4">
            <img
              className="illustration"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/c_scale,q_auto,w_560/v1614717106/Assets/Group_5680_ys9rvt.png"
              alt="illustration img"
            />
          </Col>
        </Row>
        <Row className="ROW-EIGHT">
          <Col xs="12" md="11" lg="6" xl="6">
            <ContactUsForm />
          </Col>
          <Col xs="12" md="11" lg="6" xl="6">
            <AboutUsArea />
          </Col>
        </Row>
      </Row>
      <Footer />
      </Suspense>
    </Container>
    </>
  );
};

export default Home2;
