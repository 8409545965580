import React from 'react'
import { Doughnut } from "react-chartjs-2";
const DoughnutChart = (props) => {
    return (
        <div className="charts" style={{width:"100%", minWidth:"320px", boxShadow:"0px 3px 25px #00000029", padding:"10px 0px"}}>
            <Doughnut data={props.data} options={props.options}/>
        </div>
    )
}
export default DoughnutChart
