import React,{useEffect} from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./VerifyMail.css";
import { Helmet } from "react-helmet";

const VerifyMail = () => {
  
  useEffect(() => {
    window.scrollTo({ top: 0 });
   }, [])

  return (
    <>
      <Helmet>
        <style type="text/css">{`.navbar {display: none}`}</style>
      </Helmet>
      <Container className="email-check-container">
        <Row className="check-mail-note">
          <Col xs="4"></Col>
          <span className="check-email-line">
            Check your email for the verification link
          </span>
          <Col></Col>
        </Row>
        <Row xs="1">
          <Col xs="5"></Col>
          <Col xs="7">
            <img
              className="verify-mail-icon"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto/v1614717103/Assets/check_your_email_icon_1_c77nqm.webp"
              alt="doctor's head set"
              style={{ width: "30%" }}
            />
          </Col>
          <Col></Col>
          <Row><Col xs="4"></Col><Col xs="7" className="check-spam">
            If it's not in your inbox, please check your spam folder
            </Col><Col>
            </Col>
            </Row>
        </Row>
        
      </Container>
    </>
  );
};

export default VerifyMail;
