import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { GrFormAdd } from "react-icons/gr";
import FileUploader from "../../Components/FileUploader/FileUploader";
import MediaContainer from "../../Components/MediaContainer/MediaContainer";
import "./ClientsPage.css";
import ExamService from "../../utils/ExamService.js";
import ExamDisplay from "../../Components/ExamDisplay/ExamDisplay";
import CategoryDisplay from "../../Components/CategoryDisplay/CategoryDisplay";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Notifications from "../../Components/Notifications/Notifications";
import CustomModal from "../../Components/CustomModal/CustomModal";
const ClientsPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });  
  }, []);
      // $(document).ready(function() {
      //     $('body').bind('cut copy', function(e) {
      //         e.preventDefault();
      //       });
      //   });
      //   $(document).ready(function() {
      //       $("body").on("contextmenu", function(e) {
      //           return false;
      //         });
      //     });

  let history = useHistory();
  const { user } = useSelector((state) => state.auth);
  let postCategory;
  let categoryName;
  const categoryObject = { title: "", description: "", exam: "" };
  const [examId, setExamId] = useState("");
  const [categoryId, setCateroryId] = useState();
  const [addCategory, setAddCategory] = useState("");
  const [display, setDisplay] = useState("");
  const [question, setQuestion] = useState("");
  const [questionImgs, setQuestionImgs] = useState([]);
  const [explanationImgs, setExplanationImgs] = useState([]);
  const [questionVideo, setQuestionVideo] = useState("");
  const [explanationVideo, setExplanationVideo] = useState("");
  const [explanation, SetExplanation] = useState("");
  const [lastUpdated, SetLastUpdated] = useState("");
  const [addedQuestion, setAddedQuestion] = useState(false);
  const [submitted, setSubmitted] = useState();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [error, setError] = useState();
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answerStatus, setAnswerStatus] = useState(false);
  const [message, setMessage] =  useState();
  const [toggle1, setToggle1] = useState(false)
  const [toggle2, setToggle2] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [loading , setLoading] = useState(false)
  const [references, setReferences] = useState([]);
  const [refInput, setRefInput] = useState([])
  const [modal, setModal] = useState();
 
 

  const [src1, setSrc1] = useState({
    correct: "assets/assets home/circle.png",
    wrong: "assets/assets home/circle.png",
  });
  const [src2, setSrc2] = useState({
    correct: "assets/assets home/circle.png",
    wrong: "assets/assets home/circle.png",
  });
  const [src3, setSrc3] = useState({
    correct: "assets/assets home/circle.png",
    wrong: "assets/assets home/circle.png",
  });
  const [src4, setSrc4] = useState({
    correct: "assets/assets home/circle.png",
    wrong: "assets/assets home/circle.png",
  });
  const [answer, setAnswer] = useState([
    { option1: "", flag1: "" },
    { option2: "", flag2: "" },
    { option3: "", flag3: "" },
    { option4: "", flag4: "" },
  ]);


  useEffect(() => {
    if (!user) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleEnableBtn = () =>{
    if (examId && categoryId && question && explanation && answer && answerStatus){
      setDisableSubmitBtn(false);
    }
    else setDisableSubmitBtn(true);
  }
  useEffect(() => {
    handleEnableBtn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId,categoryId,question,explanation,answer,answerStatus])

  const handleExamClick = (e) => {
    setExamId(e.target.value);
    setLoading(true);
  };
  useEffect(() => {
       setTimeout(() => {
       setLoading(false);
     },3000)
  }, [examId])
  const handleCategoryClick = (e) => {
    setCateroryId(e.target.value);
  };
  const handleChange = (e) => {
    categoryName = e.target.value;
    setAddCategory(categoryName);
  };
  const handleAddCategory = async () => {
    categoryObject.title = addCategory;
    categoryObject.exam = examId;
    postCategory = await ExamService.createCategory(categoryObject);
    setDisplay(postCategory);
    setAddCategory("")
  };
  const handleQuestionInput = (e) => {
    setQuestion(e.target.value);
  };
  const handleAnswerInput = (e) => {
    let name = e.target.name;
    switch (name) {
      case "ans1":
        setAnswer((prev) => prev, (answer[0].option1 = e.target.value));
        setAnswer1(e.target.value);
        break;
      case "ans2":
        setAnswer((prev) => prev, (answer[1].option2 = e.target.value));
        setAnswer2(e.target.value);
        break;
      case "ans3":
        setAnswer((prev) => prev, (answer[2].option3 = e.target.value));
        setAnswer3(e.target.value);
        break;
      case "ans4":
        setAnswer((prev) => prev, (answer[3].option4 = e.target.value));
        setAnswer4(e.target.value);
        break;
      default:
        return name;
    }
  };
  const handleFlags = (e) => {
    let flagName = e.target.name;
    switch (flagName) {
      case "flag1 true":
        setAnswer(
          (prev) => prev,
          ((answer[0].flag1 = true),
          (answer[1].flag2 = false),
          (answer[2].flag3 = false),
          (answer[3].flag4 = false))
        );
        setSrc1({
          correct: "assets/assets home/Group 5668.png",
          wrong: "assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        break;
      case "flag2 true":
        setAnswer(
          (prev) => prev,
          ((answer[1].flag2 = true),
          (answer[0].flag1 = false),
          (answer[2].flag3 = false),
          (answer[3].flag4 = false))
        );
        setSrc2({
          correct: "assets/assets home/Group 5668.png",
          wrong: "assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        break;

      case "flag3 true":
        setAnswer(
          (prev) => prev,
          ((answer[2].flag3 = true),
          (answer[0].flag1 = false),
          (answer[1].flag2 = false),
          (answer[3].flag4 = false))
        );
        setSrc3({
          correct: "assets/assets home/Group 5668.png",
          wrong: "assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        break;

      case "flag4 true":
        setAnswer(
          (prev) => prev,
          ((answer[3].flag4 = true),
          (answer[2].flag3 = false),
          (answer[1].flag2 = false),
          (answer[0].flag1 = false))
        );
        setSrc4({
          correct: "assets/assets home/Group 5668.png",
          wrong: "assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        break;
      case "flag4 false":
        setAnswer((prev) => prev, (answer[3].flag4 = false));
        setSrc4({
          wrong: "assets/assets home/Group 5669.png",
          correct: "assets/assets home/circle.png",
        });
        break;
      default:
        return flagName;
    }
    setAnswerStatus(true);// This state is used to indicate that true and false answers are chosen
  };

  const handleAddExplanation = (e) => {
    SetExplanation(e.target.value);
  };
  const handleLastUpdated = (e) => {
    SetLastUpdated(e.target.value);
  };

  const uploadImage = async (e) => {
    let id = e.target.id;
    const files = e.target.files;
    try{
      let res;
      let images = [];
      for(let i = 0; i < files.length; i++){
        const formData = new FormData();
        formData.append("upload_preset", "zwne8dgu");
        formData.append("file", files[i]);
        
        res = await axios.post(
      " https://api.cloudinary.com/v1_1/hnknwhgxi/image/upload",
      formData,
    );
    if(res.status === 200){
      images.push(res.data.secure_url)
    }
        }
    if(res.status === 200){
      setError(false);
      setToggle2(!toggle2);
    }

    if (res) {
      if (id === "questionImg") {
        images = images.concat(questionImgs);
        setQuestionImgs(images);
      } else {
        images = images.concat(explanationImgs);
        setExplanationImgs(images);
      }
    } else {
      console.log("something went wrong");
    }
  } catch(err){
    console.log(err)
    setError(true);
    setToggle2(!toggle2);
  }
  };

  const handleDeleteImage = (imgSrc, idx) => {
    if (idx === 1) {
      questionImgs.map((img, i) => {
        if (imgSrc === img) {
          questionImgs.splice(i, 1);
        }
      });
      setQuestionImgs(questionImgs);
      setModal(<CustomModal show={false} />);
    }
    if (idx === 2) {
      explanationImgs.map((img, i) => {
        if (imgSrc === img) {
          explanationImgs.splice(i, 1);
        }
      });
      setExplanationImgs(explanationImgs);
      setModal(<CustomModal show={false} />);
    }
  };
  const handleModal = (e, idx) => {
    var src = e.target.src;
    setModal(
      <CustomModal
        bodyType="text"
        text="Are you sure you want to delete this image?"
        title="Delete Image"
        btnText="Delete"
        show={true}
        onClick={() => handleDeleteImage(src, idx)}
      />
    );
  };
const handleAddVideo =  (e) => {
  let id = e.target.id;
  if(id === "questionVideo"){
    setQuestionVideo(e.target.value);
  }
  if(id === "explanationVideo"){
    setExplanationVideo(e.target.value);
  }
}

 const handleAddReference = (e) =>{
      setRefInput([...refInput,{ ref : e.target.value}])
    }
    let list=[];
    const handleNewReference = (e, i) => {
      const refValue = e.target.value;
      list= [...references];
      list[i] = {ref: refValue};
      setReferences(list);
    }; 
  const postQuestion = async (data) => {
   let addQuestion = await ExamService.createQuestion(data);
   if(addQuestion.status === 200){
    setSubmitted(true);
    setToggle1(!toggle1);
   }
   else if(addQuestion.status !== 200){
    setSubmitted(false);
    setToggle1(!toggle1);
   }
   
  };
  const clearValues = () => {
    setQuestion("");
    setQuestionImgs("");
    setExplanationImgs("");
    setAnswer1("");
    setAnswer2("");
    setAnswer3("");
    setAnswer4("");
    setQuestionVideo("");
    setExplanationVideo("");
    setReferences([]);
    setRefInput([]);
    setSubmitted();
    setError();
    setSrc1({
      correct: "assets/assets home/circle.png",
      wrong: "assets/assets home/circle.png",
    });
    setSrc2({
      correct: "assets/assets home/circle.png",
      wrong: "assets/assets home/circle.png",
    });
    setSrc3({
      correct: "assets/assets home/circle.png",
      wrong: "assets/assets home/circle.png",
    });
    setSrc4({
      correct: "assets/assets home/circle.png",
      wrong: "assets/assets home/circle.png",
    });
    setAnswer([
      { option1: "", flag1: "" },
      { option2: "", flag2: "" },
      { option3: "", flag3: "" },
      { option4: "", flag4: "" },
    ]);
    setExplanationImgs("");
    SetExplanation("");
    SetLastUpdated("");
    setAnswerStatus(false);
  };

  const handleSubmit = () => {
    let data = {
      question: question,
      answer: answer,
      explanation: explanation,
      lastUpdated: lastUpdated,
      category: categoryId,
      questionImgs: questionImgs.length > 0? questionImgs: null,
      explanationImgs: explanationImgs.length> 0? explanationImgs: null,
      questionVideo: questionVideo,
      explanationVideo: explanationVideo,
      references: references,
    }
    postQuestion(data);
    setAddedQuestion(!addedQuestion);
    setDisableSubmitBtn(true);
    clearValues();
  };
useEffect(() => {

  if(submitted === true){
    setMessage( <Notifications type= "success" message="Question Added" position="top-center"/>)
  }
  if(submitted === false){
    setMessage(<Notifications  type= "error" message="Question Addition Failed" position="top-center"/>)
  }  
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [toggle1])


useEffect(() => {
  if(error === false){
    setMessage( <Notifications type= "info" message="Image Uploaded" position="top-center"/>)

  }
  if(error === true){
    setMessage( <Notifications type= "warn" message="Image Not Uploaded" position="top-center"/>)

  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [toggle2])

  return (
    <>
    <Container className="clients-page-container" >
     {message}
      <Row style={{ marginLeft: "2rem" }}>
        <Row>
          <Col>
            <img
              className="pencil"
              src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717103/Assets/client_page_pencil_bjqjbo.webp"
              alt="#"
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="client-category-box-one" xs="12" md="8" lg="3">
            <Row>
              <Col className="box-title-one">Choose Your Exam</Col>
            </Row>

            <fieldset>
              <Form.Group as={Row}>
                <Col>
                  <ExamDisplay onClick={handleExamClick} />
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="client-category-box" xs="12" md="8" lg="3">
            <Row>
              <Col>
                <img
                  className="box-title"
                  src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717102/Assets/categories_stick_vlwpef.webp"
                  alt="#"
                />
              </Col>
            </Row>
            <fieldset>
              <Form.Group as={Row}>
                <Col>
                  <Row
                    className="client-category-radio"
                    style={{ paddingTop: "0px" }}
                  >
                    <Col lg="8">
                      <Form.Control
                        className="add-category"
                        type="text"
                        placeholder="Add category"
                        onChange={handleChange}
                        value={addCategory}
                      />
                    </Col>
                    <Col className="client-category-tracker">
                      <GrFormAdd
                        className="add-btn"
                        style={{ fontSize: "1.2rem", paddingBottom: "3px" }}
                        onClick={handleAddCategory}
                      />
                    </Col>
                  </Row>
                  
                    <CategoryDisplay
                  categoryId={categoryId}
                  examId={examId}
                  display={display}
                  showDiff={addedQuestion}
                  onClick={handleCategoryClick}
               />
                 
                
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="add-answer-box" xs="12" md="8" lg="3">
            <Col style={{ padding: "0px" }}>
              {" "}
              <img
                className="question-mark-img"
                src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717109/Assets/quest-mark_lty5fw.webp"
                alt="#"
              />
            </Col>
            <Col xs="9" className="add-quest-title title-highlights">
              Add Question
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4">
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              row={3}
              placeholder=" Question goes here"
              className="question-input"
              onChange={handleQuestionInput}
              value={question}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
         className="add-answer-box" xs="12" md="8" lg="3"
            style={{
              height: "auto",
              flewDirection: "column",
              paddingBottom: "2.5rem",
             
            }}
          >
            <Col>
            <MediaContainer
              data={questionImgs}
              type="image"
              onClick={(e) => handleModal(e, 1)}
            />
            </Col>
            <Col className="uploader" xs="2" md="2" lg="2">
              <FileUploader onChange={uploadImage} id="questionImg" style={{ cursor: "pointer" }}/>
            </Col>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="add-answer-box" xs="12" md="8" lg="3">
            <Col xs="9" className="add-quest-title title-highlights">
              Question Media 
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
           
            <Form.Control
              type="text"
              placeholder=" Video Url goes here"
              className="question-input"
              id="questionVideo"
               onChange={handleAddVideo}
               value={questionVideo}
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: "5rem", marginLeft: "0", width: "100%" }}
          xs="1"
          lg="3"
        >
          <Col xs="11" md="11" lg="4" className="answer-column">
            <Row>
              <Col className="answer-title">Answers</Col>
            </Row>
            <Row>
              <Col className="answer-rows" xs="8" md="7" lg="7">
                <Form.Control
                  as="textarea"
                  row={2}
                  name="ans1"
                  placeholder=" Answer 1:"
                  className="answer-input"
                  onChange={handleAnswerInput}
                  value={answer1}
                />
              </Col>
              <Col xs="1" className="answer-rows">
                <Col
                  xs="1"
                  md="2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={src1.correct}
                      alt="#"
                      onClick={handleFlags}
                      name="flag1 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={src1.wrong}
                      alt="#"
                      onClick={handleFlags}
                      name="flag1 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                   as="textarea"
                   row={2}
                  name="ans2"
                  placeholder=" Answer 2:"
                  className="answer-input"
                  onChange={handleAnswerInput}
                  value={answer2}
                />
              </Col>
              <Col xs="1" className="answer-rows">
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={src2.correct}
                      alt="#"
                      onClick={handleFlags}
                      name="flag2 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={src2.wrong}
                      alt="#"
                      onClick={handleFlags}
                      name="flag2 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                  as="textarea"
                  row={2}
                  name="ans3"
                  placeholder=" Answer 3:"
                  className="answer-input"
                  onChange={handleAnswerInput}
                  value={answer3}
                />
              </Col>
              <Col xs="1" className="answer-rows">
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={src3.correct}
                      alt="#"
                      onClick={handleFlags}
                      name="flag3 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={src3.wrong}
                      alt="#"
                      onClick={handleFlags}
                      name="flag3 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                   as="textarea"
                   row={2}
                  name="ans4"
                  placeholder=" Answer 4:"
                  className="answer-input"
                  onChange={handleAnswerInput}
                  value={answer4}
                />
              </Col>
              <Col xs="1" className="answer-rows">
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={src4.correct}
                      alt="#"
                      onClick={handleFlags}
                      name="flag4 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={src4.wrong}
                      alt="#"
                      onClick={handleFlags}
                      name="flag4 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col className="add-answer-box" xs="12" md="8" lg="3">
            <Col xs="9" className="add-quest-title title-highlights">
              Explanation
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4">
              {/* <FileUploader onChange={uploadImage} id="explanationImg" /> */}
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              placeholder=" Explanation goes here"
              className="question-input"
              onChange={handleAddExplanation}
              value={explanation}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="add-answer-box" xs="12" md="8" lg="3">
            <Col xs="9" className="add-quest-title title-highlights">
              Last Updated
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4">
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              placeholder=" Last Updated Info goes here"
              className="question-input"
              onChange={handleLastUpdated}
              value={lastUpdated}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            className="add-answer-box" xs="12" md="8" lg="3"
            style={{
              height: "auto",
              flewDirection: "column",
              paddingBottom: "2.5rem",
            }}
          >
            <Col>
              {" "}
              <MediaContainer
                data={explanationImgs}
                type="image"
                onClick={(e) => handleModal(e, 2)}
              />
            </Col>
            <Col className="uploader" xs="2" md="2" lg="2">
              <FileUploader onChange={uploadImage} id="explanationImg" />
            </Col>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col className="add-answer-box" xs="12" md="8" lg="3">
            <Col className="add-quest-title title-highlights" xs="8" >
              Explanation Media 
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
           
            <Form.Control
              type="text"
              placeholder=" Video Url goes here"
              className="question-input"
              id="explanationVideo"
               onChange={handleAddVideo}
               value={explanationVideo}
            />
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col className="add-ref-box" xs="12" md="8" lg="3" style={{height:"auto", paddingBottom:"2rem"}}>
            <Col xs="10" className="add-quest-title title-highlights">
              References
            </Col>
            <Col xs="1">
             <Button onClick = {handleAddReference} className= "add-reference-btn" variant="dark" style={{marginLeft:"-1rem", marginTop:"-3rem", paddingTop:"1px"}}> + </Button>
            </Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Row xs="1"style={{ marginTop:"3rem",marginLeft:"1rem"}} >
            {refInput &&
                Array.isArray(refInput) &&
                refInput.map((elem, index) => {
                  return (
                      <Row  key = {index} xs="12">
                        <Form.Control
                          type="text"
                          placeholder=" Reference goes here"
                          className="reference-input"
                          id={index}
                          onChange = {(e)=>handleNewReference(e, index)}
                          key = {index * 0.01}
                        />
                      </Row>
                  );
                })}
               </Row>
          </Col>
        </Row>

        <Row xs="1"  style={{ marginTop: "3rem", marginBottom: "3rem"}}>
          {" "}
          <button
            type="submit"
            className="client-submit-btn"
            onClick={handleSubmit}
            disabled = {disableSubmitBtn}
          >
            Submit Answer
          </button>
        </Row>
      </Row>
      {modal && modal}
    </Container>
    </>
  );
};

export default ClientsPage;
