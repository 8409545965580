import axios from "axios";
import authHeader from "../services/auth-header";

// const API_URL = "http://localhost:5000";
// const API_URL2 = "http://localhost:5000";

 const API_URL ="";
 const API_URL2 = "";

//register
const register = (username, email, password) => {
  const response = axios.post(API_URL + "/api/auth/signup", {
    username,
    email,
    password,
  });
  return response;
};

//logIn
const login = (username, password, emailToken, role) => {
  return axios
    .post(API_URL + "/api/auth/signin", {
      username,
      password,
      emailToken,
      role,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};
//logOut
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("questionsData");
  localStorage.removeItem("questions");
  localStorage.removeItem("demo");
  localStorage.removeItem("demoExam");
  localStorage.removeItem("Pages");
  localStorage.removeItem("Score");
  localStorage.removeItem("first Entry");
  localStorage.removeItem("explanation Video");
  localStorage.removeItem("Question Video");
  localStorage.removeItem("Page Number");
  localStorage.removeItem("page Number");
  localStorage.removeItem("CorrectAnswers");
  localStorage.removeItem("paymentInfo");
  localStorage.removeItem("timer");
  localStorage.removeItem("exam");
};

//Change Password
const changePassword = (userId, password) => {
  return axios.post(
    API_URL + `/api/auth/changePassword`,
    {
      userId,
      password,
    },
    { headers: authHeader() }
  );
};
const resetPassword = (email) => {
  return axios.post(API_URL + `/api/auth/resetPassword`, {
    email,
  });
};
const getCurrentUser = (user) => {
  return axios.post(API_URL + "/api/auth/getCurrentUser", user);
};
//Contact Us
const contactUs = (data) => {
  return axios.post(API_URL + `/api/auth/contactUs`, data);
};
const unSubscribe = (data) => {
  return axios.post(API_URL + `/api/users/unSubscribe`, data, {
    headers: authHeader(),
  });
};
const saveUserExam = (data) => {
  return axios.post(API_URL + `/api/users/saveUserExam`, data, {
    headers: authHeader(),
  });
};
const paymentRequest = (data) => {
  return axios.post(API_URL2 + "/api/users/payment", data, {
    headers: authHeader(),
  });
};
const queryTransaction = (data) => {
  return axios.post(API_URL2 + "/api/users/queryTransaction", data, {
    headers: authHeader(),
  });
};
const handleReturnUrl = (data) => {
  return axios.post(API_URL2 + "api/users/returnToPage", data);
};
const getAllUsers = () => {
  return axios.get(API_URL + "/api/auth/getAllUsers", {
    headers: authHeader(),
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  register,
  login,
  logout,
  contactUs,
  unSubscribe,
  saveUserExam,
  getCurrentUser,
  changePassword,
  resetPassword,
  paymentRequest,
  queryTransaction,
  handleReturnUrl,
  getAllUsers,
};
