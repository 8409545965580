import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home2 from "./Pages/Home/Home2";
import SignupUser from "./Pages/SignupUser/SignupUser";
import VerifyMail from "./Pages/VerifyMail/VerifyMail";
import AdminLogin from "./Pages/AdminLogin/AdminLogin";
import UserLogin from "./Pages/UserLogin/UserLogin";
import TakeDemo from "./Pages/TakeDemo/TakeDemo";
import ClientsPage from "./Pages/ClientsPage/ClientsPage";
import TimedTestPage from "./Pages/TimedTestPage/TimedTestPage";
import History from "./Pages/History/History";
import UserAccount from "./Pages/UserAccount/UserAccount";
import StartQuestion from "./Pages/StartQuestion/StartQuestion";
import Navbar from "./Components/Navbar/Navbar";
import AppNavbarTwo from "./Components/NavbarTwo/NavbarTwo";
import ExamCategories from "./Pages/ExamCategories/ExamCategories";
import PaymentPage from "./Pages/PaymentPage/PaymentPage";
import PaymentResult from "./Pages/PaymentResult/PaymentResult";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Archive from "./Pages/Archive/Archive";
import QuestionEditor from "./Pages/QuestionEditor/QuestionEditor";

// Redux
import { useSelector } from "react-redux";
import { AdminUrl } from "./Pages/AdminLogin/AdminUrl";

function App() {

  const { user } = useSelector((state) => state.auth);
  const [navDisplay, setNavDisplay] = useState("");
 

  useEffect(() => {
    if (user) {
      setNavDisplay(<AppNavbarTwo className="navbar" />);
    } else if (!user) {
      setNavDisplay(<Navbar className="navbar" />);
    }
  }, [user]);
  
 
  return (
    <Router>
      {navDisplay}
      <Switch>
        <Route exact path="/" component={Home2} />
        <Route exact path="/TakeDemo" component={TakeDemo} />
        <Route exact path="/ClientsPage" component={ClientsPage} />
        <Route exact path="/TimedTest" component={TimedTestPage} />
        <Route exact path="/History" component={History} />
        <Route exact path="/StartQuestion" component={StartQuestion} />
        <Route exact path="/ExamCategories" component={ExamCategories} />
        <Route exact path="/PaymentPage" component={PaymentPage} />
        <Route path="/paymentresult/:returnToken"component={PaymentResult}/>
        <Route exact path="/SignUp" component={SignupUser} />
        <Route exact path="/VerifyMail" component={VerifyMail} />
        <Route exact path={AdminUrl.key1} component={AdminLogin} />
        <Route exact path="/User/:token" component={UserLogin} />
        <Route exact path="/UserAccount" component={UserAccount} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/Archive" component={Archive} />
        <Route exact path="/QuestionEditor/:questionId" component={QuestionEditor} />
      </Switch>
    </Router>
  );
}

export default App;
