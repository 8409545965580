/* eslint-disable default-case */
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const Notifications = (props) => {

    const notify = ()=>{
        if(props.type === 'success'){
            toast.success(props.message,{position: toast.POSITION.TOP_CENTER, hideProgressBar: true},)
        }
        if(props.type === 'error'){
            toast.error(props.message,{position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
        if(props.type === 'info' && props.position === "top-center"){
            toast.info(props.message,{position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
        if(props.type === 'info' && props.position === "top-right"){
            toast.info(props.message,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
        if(props.type === 'info' && props.position === "top-left"){
            toast.info(props.message,{position: toast.POSITION.TOP_LEFT, hideProgressBar: true})
        }
        if(props.type === 'info' && props.position === "bottom-left"){
            toast.info(props.message,{position: toast.POSITION.BOTTOM_LEFT, hideProgressBar: true})
        }
        if(props.type === 'info' && props.position === "bottom-right"){
            toast.info(props.message,{position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true})
        }
        if(props.type === 'warn' && props.position === "bottom-left"){
            toast.warn(props.message,{position: toast.POSITION.BOTTOM_LEFT, hideProgressBar: true})
        }
        
    }
 
    notify ();
  return (
  <></>
  );
};

export default Notifications;
