/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import ExamService from "../../utils/ExamService.js";

import { Col, Container, Row, Form, Button } from "react-bootstrap";
import {useHistory } from "react-router-dom";
import FileUploader from "../../Components/FileUploader/FileUploader";
import MediaContainer from "../../Components/MediaContainer/MediaContainer";
import Notifications from "../../Components/Notifications/Notifications";
import { MdModeEdit, MdDelete } from "react-icons/md";
import CustomModal from "../../Components/CustomModal/CustomModal";
import axios from "axios";
import "./QuestionEditor.css";

const QuestionEditor = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  let history = useHistory();
  const id = props.match.params.questionId;
  let questionData;
  const [modal, setModal] = useState();
  const [questionText, setQuestionText] = useState();
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [questionExplanation, setQuestionExplanation] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [questionImages, setQuestionImages] = useState();
  const [explanationImages, setExplanationImages] = useState();
  const [refs, setRefs] = useState();
  const [questVideo, setQuestVideo] = useState();
  const [expVideo, setExpVideo] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [enableFlagEdit, setEnableFlagEdit] = useState(false);
  const [toggle2, setToggle2] = useState(false)
  const [toggle1, setToggle1] = useState(false)
  const [submitted, setSubmitted] = useState();
  const [message, setMessage] =  useState();
  const [error, setError] = useState();
  const [references, setReferences] = useState([]);
  const [refInput, setRefInput] = useState([]);
  const [srcMain, setSrcMain] = useState("/assets/assets home/circle.png");
  const [srcCorrect, setSrcCorrect] = useState( "/assets/assets home/Group 5668.png");
  const [srcWrong, setSrcWrong] = useState(
    "/assets/assets home/Group 5669.png"
  );

  const [src1, setSrc1] = useState({
    correct: "/assets/assets home/circle.png",
    wrong: "/assets/assets home/circle.png",
  });
  const [src2, setSrc2] = useState({
    correct: "/assets/assets home/circle.png",
    wrong: "/assets/assets home/circle.png",
  });
  const [src3, setSrc3] = useState({
    correct: "/assets/assets home/circle.png",
    wrong: "/assets/assets home/circle.png",
  });
  const [src4, setSrc4] = useState({
    correct: "/assets/assets home/circle.png",
    wrong: "/assets/assets home/circle.png",
  });

  useEffect(() => {
    getQuestionToEdit(id);
  }, [id]);

  let answerArray = [];
  let questionImagesArray = [];
  let explanationImagesArray = [];
  const getQuestionToEdit = async (ID) => {
    questionData = await ExamService.getQuestion(ID);
    let questionObj = questionData.data;
    if (questionObj) {
      setQuestionText(questionObj.text);
      for (let i = 0; i < questionObj.answer.length; i++) {
        answerArray.push({
          ans: questionObj.answer[i][`option${i + 1}`],
          flag: questionObj.answer[i][`flag${i + 1}`],
        });
      }
      setQuestionAnswers(answerArray);
      setQuestionExplanation(questionObj.explanation);
      setLastUpdated(questionObj.lastUpdated);
      if(questionObj.questionImgs){
        for(let i = 0; i < questionObj.questionImgs.length; i++){
          if(questionObj.questionImgs && questionObj.questionImgs[i] !== ""){
            questionImagesArray.push(questionObj.questionImgs[i])
          }
        }
      }
      if(questionObj.explanationImgs){
        for(let i = 0; i < questionObj.explanationImgs.length; i++){
          if(questionObj.explanationImgs && questionObj.explanationImgs[i] !== ""){
            explanationImagesArray.push(questionObj.explanationImgs[i])
          }
        }
      }

      setQuestionImages(questionImagesArray);
      setExplanationImages(explanationImagesArray);
      setRefs(questionObj.references);
      setQuestVideo(questionObj.questionVideo);
      setExpVideo(questionObj.explanationVideo);
    }
  };

  const cloneAnswerObjects = () => {
    let transitionArray = [];
    for(let i= 0; i<questionAnswers.length; i++){
      transitionArray.push({[`option${i+1}`]:  questionAnswers[i].ans, [`flag${i + 1}`]: questionAnswers[i].flag})
    }
     return transitionArray
   };


  const handleModal = (e, idx) => {
    var src = e.target.src;
    setModal(
      <CustomModal
        bodyType="text"
        text="Are you sure you want to delete this image?"
        title="Delete Image"
        btnText="Delete"
        show={true}
        onClick={() => handleDeleteImage(src, idx)}
      />
    );
  };
  const handleDeleteImage = (imgSrc, idx) => {
    if (idx === 1) {
      questionImages.map((img, i) => {
        if (imgSrc === img) {
          questionImages.splice(i, 1);
        }
      });
      setQuestionImages(questionImages);
      setModal(<CustomModal show={false} />);
    }
    if (idx === 2) {
      explanationImages.map((img, i) => {
        if (imgSrc === img) {
          explanationImages.splice(i, 1);
        }
      });
      setExplanationImages(explanationImages);
      setModal(<CustomModal show={false} />);
    }
  };


  const handleQuestionInput = (e) => {
    setQuestionText(e.target.value);
  };

  const handleFlags = (e) => {
    if (enableEdit) {
      setEnableFlagEdit(true);
    }
    let flagName = e.target.name;
    switch (flagName) {
      case "flag1 true":
        setQuestionAnswers(
          (prev) => prev,
          ((questionAnswers[0].flag = true),
            (questionAnswers[1].flag = false),
            (questionAnswers[2].flag = false),
            (questionAnswers[3].flag = false))
        );
        setSrc1({
          correct: "/assets/assets home/Group 5668.png",
          wrong: "/assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        break;
      case "flag2 true":
        setQuestionAnswers(
          (prev) => prev,
          ((questionAnswers[1].flag = true),
            (questionAnswers[0].flag = false),
            (questionAnswers[2].flag = false),
            (questionAnswers[3].flag = false))
        );

        setSrc2({
          correct: "/assets/assets home/Group 5668.png",
          wrong: "/assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        break;

      case "flag3 true":
        setQuestionAnswers(
          (prev) => prev,
          ((questionAnswers[2].flag = true),
            (questionAnswers[0].flag = false),
            (questionAnswers[1].flag = false),
            (questionAnswers[3].flag = false))
        );

        setSrc3({
          correct: "/assets/assets home/Group 5668.png",
          wrong: "/assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc4({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        break;

      case "flag4 true":
        setQuestionAnswers(
          (prev) => prev,
          ((questionAnswers[3].flag = true),
            (questionAnswers[2].flag = false),
            (questionAnswers[1].flag = false),
            (questionAnswers[0].flag = false))
        );

        setSrc4({
          correct: "/assets/assets home/Group 5668.png",
          wrong: "/assets/assets home/circle.png",
        });
        setSrc3({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc2({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        setSrc1({
          wrong: "/assets/assets home/Group 5669.png",
          correct: "/assets/assets home/circle.png",
        });
        break;
      default:
        return flagName;
    }

    // setAnswerStatus(true);// This state is used to indicate that true and false answers are chosen
  };

  const handleAnswerInput = (e) => {
    let name = e.target.name;
    switch (name) {
      case "ans1":
        setQuestionAnswers(
          [...questionAnswers],
          (questionAnswers[0].ans = e.target.value)
        );
        break;
      case "ans2":
        setQuestionAnswers(
          [...questionAnswers],
          (questionAnswers[1].ans = e.target.value)
        );
        break;
      case "ans3":
        setQuestionAnswers(
          [...questionAnswers],
          (questionAnswers[2].ans = e.target.value)
        );
        break;
      case "ans4":
        setQuestionAnswers(
          [...questionAnswers],
          (questionAnswers[3].ans = e.target.value)
        );
        break;
      default:
        return name;
    }
  };

  const handleAddExplanation = (e) => {
    setQuestionExplanation(e.target.value);
  };
  const handleEditLastUpdated = (e) => {
    setLastUpdated(e.target.value);
  };

  const handleAddReference = (e) => {
    setRefInput([...refInput, { ref: e.target.value }]);
  };
  let list=[];
  const handleNewReference = (e, i) => {
    const refValue = e.target.value;
    list= [...references];
    list[i] = {ref: refValue};
    setReferences(list);
  };

  const uploadImage = async (e) => {
    let id = e.target.id;
    const files = e.target.files;
    try {
      let res;
      let images = [];
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("upload_preset", "zwne8dgu");
        formData.append("file", files[i]);

        res = await axios.post(
          " https://api.cloudinary.com/v1_1/hnknwhgxi/image/upload",
          formData
        );
        if (res.status === 200) {
          images.push(res.data.secure_url);
        }
      }
      if (res.status === 200) {
        setError(false);
        setToggle2(!toggle2);
      }

      if (res) {
        if (id === "questionImg") {
          images = images.concat(questionImages);
          setQuestionImages(images);
        } else {
          images = images.concat(explanationImages);
          setExplanationImages(images);
        }
      } else {
        console.log("something went wrong");
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setToggle2(!toggle2);
    }
  };

  const handleGoBack = () => {
    history.push({
      pathname: "/Archive",
    });
  };

  const handleEdit = () => {
    setEnableEdit(true);
  };

  const handleSubmit = async () => {
    
    let data = {
      id: id,
      text: questionText,
      explanation: questionExplanation,
      lastUpdated: lastUpdated,
      questionImgs: questionImages,
      explanationImgs: explanationImages,
      answer: cloneAnswerObjects(),
      questionVideo: localStorage.getItem("Question Video")
        ? localStorage.getItem("Question Video")
        : questVideo,
      explanationVideo: localStorage.getItem("explanation Video")
        ? localStorage.getItem("explanation Video")
        : expVideo,
      references: localStorage.getItem("Existing Reference")
        ? references.concat( JSON.parse(localStorage.getItem("Existing Reference")))
        : references.concat(refs),
    };
    console.log("data.questionExplanation", data.questionExplanation);
    const editResponse = await ExamService.editQuestionById(id, data);
    if(editResponse.status === 200){
      setSubmitted(true);
      setToggle1(!toggle1);
     }
     else if(editResponse.status !== 200){
      setSubmitted(false);
      setToggle1(!toggle1);
     }
    localStorage.removeItem("Existing Reference")
  };
  useEffect(() => {
    if(error === false){
      setMessage( <Notifications type= "info" message="Image Uploaded" position="top-center"/>)
  
    }
    if(error === true){
      setMessage( <Notifications type= "warn" message="Image Not Uploaded" position="top-center"/>)
  
    }
  }, [toggle2])

useEffect(() => {
  if(submitted === true){
    setMessage( <Notifications type= "success" message="Question Edited" position="top-center"/>)
  }
  if(submitted === false){
    setMessage(<Notifications  type= "error" message="Failed to save changes" position="top-center"/>)
  }  
}, [toggle1])

  const handleDelete = async() => {
   await ExamService.deleteQuestion(id);

    setTimeout(() => {
      handleGoBack()
    }, 1000);
   };

  return (
   
      <Container
       className="padding-control"
        style={{ marginTop: "10rem",marginRight:"1%"}}
      >
        {message}
        <button
          className="edit-btn"
          onClick={handleEdit}
        >
          <MdModeEdit />
        </button>
        <button className="delete-btn" onClick={handleDelete}>
          {" "}
          <MdDelete />
        </button>
        <Row >
          <Col className="add-answer-box" xs="11" md="8" lg="6"
            style={{height:"250px"}}
            >
            <Col style={{ padding: "0px" }}>
              {" "}
              {/* <img
                className="question-mark-img"
                src="https://res.cloudinary.com/hnknwhgxi/image/upload/f_auto,q_auto/v1614717109/Assets/quest-mark_lty5fw.webp"
                alt="#"
              /> */}
            </Col>
            <Col xs="9" className="add-quest-title title-highlights"  >
              Question
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4"></Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder=" Question goes here"
              className="question-input"
              onChange={ enableEdit? handleQuestionInput : null}
              value={questionText}
              style={{ height: "auto" }}
            />
          </Col>
        </Row>
        <Row >
          <Col
          className="add-answer-box" xs="11" md="8" lg="6"
            style={{
              height: "auto",
              flewDirection: "column",
              paddingBottom: "2.5rem",
             
            }}
          >
            <Col>
            <MediaContainer
              data={questionImages}
              type="image"
              onClick={(e) => handleModal(e, 1)}
            />
            </Col>
            <Col className="uploader" xs="2" md="2" lg="2">
              <FileUploader onChange={uploadImage} id="questionImg" style={{ cursor: "pointer" }}/>
            </Col>
          </Col>
        </Row>

        <Row
          style={{ marginTop: "3rem", marginLeft: "0", width: "100%" }}
          xs="1"
          lg="3"
          className = "editor-ans-row"
        >
          <Col xs="11" md="11" lg="9" className="answer-column">
            <Row>
              <Col className="answer-title">Answers</Col>
            </Row>
            <Row>
              <Col className="answer-rows" xs="8" md="7" lg="7">
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="ans1"
                  placeholder=" Answer 1:"
                  className="answer-input"
                  style={{ height: "auto", width: "100%" }}
                  onChange={enableEdit ? handleAnswerInput : null}
                  value={
                    questionAnswers && questionAnswers[0]
                      ? questionAnswers[0].ans
                      : ""
                  }
                />
              </Col>
              <Col
                xs="1"
                className="answer-rows"
                // style={{ marginTop: "-4rem" }}
              >
                <Col
                  xs="1"
                  md="2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src1.correct
                          : questionAnswers && questionAnswers[0]
                            ? questionAnswers[0].flag === true
                              ? srcCorrect
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag1 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src1.wrong
                          : questionAnswers && questionAnswers[0]
                            ? questionAnswers[0].flag === false
                              ? srcWrong
                              : srcMain
                            : srcMain
                      }
                      alt="circle"
                      onClick={handleFlags}
                      name="flag1 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="ans2"
                  placeholder=" Answer 2:"
                  className="answer-input"
                  style={{ height: "auto", width: "100%" }}
                  onChange={enableEdit ? handleAnswerInput : null}
                  value={
                    questionAnswers && questionAnswers[1]
                      ? questionAnswers[1].ans
                      : ""
                  }
                />
              </Col>
              <Col
                xs="1"
                className="answer-rows"
                // style={{ marginTop: "-4rem" }}
              >
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src2.correct
                          : questionAnswers && questionAnswers[1]
                            ? questionAnswers[1].flag === true
                              ? srcCorrect
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag2 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src2.wrong
                          : questionAnswers && questionAnswers[1]
                            ? questionAnswers[1].flag === false
                              ? srcWrong
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag2 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="ans3"
                  placeholder=" Answer 3:"
                  className="answer-input"
                  style={{ height: "auto", width: "100%" }}
                  onChange={enableEdit ? handleAnswerInput: null}
                  value={
                    questionAnswers && questionAnswers[2]
                      ? questionAnswers[2].ans
                      : ""
                  }
                />
              </Col>
              <Col
                xs="1"
                className="answer-rows"
              >
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src3.correct
                          : questionAnswers && questionAnswers[2]
                            ? questionAnswers[2].flag === true
                              ? srcCorrect
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag3 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src3.wrong
                          : questionAnswers && questionAnswers[2]
                            ? questionAnswers[2].flag === false
                              ? srcWrong
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag3 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col xs="8" md="7" lg="7" className="answer-rows">
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="ans4"
                  placeholder=" Answer 4:"
                  className="answer-input"
                  style={{ height: "auto", width: "100%" }}
                  onChange={enableEdit ? handleAnswerInput : null}
                  value={
                    questionAnswers && questionAnswers[3]
                      ? questionAnswers[3].ans
                      : ""
                  }
                />
              </Col>
              <Col
                xs="1"
                className="answer-rows"
                // style={{ marginTop: "-4rem" }}
              >
                <Col xs="2" style={{ display: "flex", flexDirection: "row" }}>
                  <Col style={{ marginTop: "-3px" }}>
                    True
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src4.correct
                          : questionAnswers && questionAnswers[3]
                            ? questionAnswers[3].flag === true
                              ? srcCorrect
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag4 true"
                    />
                  </Col>

                  <Col style={{ marginTop: "-3px" }}>
                    {" "}
                    False
                    <br />
                    <img
                      src={
                        enableFlagEdit
                          ? src4.wrong
                          : questionAnswers && questionAnswers[3]
                            ? questionAnswers[3].flag === false
                              ? srcWrong
                              : srcMain
                            : srcMain
                      }
                      alt="#"
                      onClick={handleFlags}
                      name="flag4 false"
                    />
                  </Col>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row >
          <Col className="add-answer-box" xs="11" md="8" lg="6" style={{height:"250px"}}>
            <Col xs="10" className="add-quest-title title-highlights">
              Explanation
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4"></Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              row={5}
              placeholder=" Explanation goes here"
              className="question-input"
              onChange={enableEdit ? handleAddExplanation : null}
              value={questionExplanation}
              style={{ height: "auto" }}
            />
          </Col>
        </Row>
        <Row >
          <Col className="add-answer-box" xs="11" md="8" lg="6" style={{height:"250px"}}>
            <Col xs="10" className="add-quest-title title-highlights">
              Last Updated
            </Col>
            <Col className="uploader" xs="5" md="4" lg="4"></Col>
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Form.Control
              as="textarea"
              row={5}
              placeholder=" Last Updated Info goes here"
              className="question-input"
              onChange={enableEdit ? handleEditLastUpdated : null}
              value={lastUpdated}
              style={{ height: "auto" }}
            />
          </Col>
        </Row>
        <Row >
          <Col
            className="add-answer-box"
            xs="11"
            md="8"
            lg="6"
            style={{
              height: "auto",
              flewDirection: "column",
              paddingBottom: "2.5rem",
              
            }}
          >
            <Col>
              {" "}
              <MediaContainer
                data={explanationImages}
                type="image"
                onClick={(e) => handleModal(e, 2)}
              />
            </Col>
            <Col className="uploader" xs="2" md="2" lg="2">
              <FileUploader onChange={uploadImage} id="explanationImg" />
            </Col>
          </Col>
        </Row>

        <Row style={{ height: "auto" }}>
          <Col className="add-answer-box" xs="11" md="8" lg="6" style={{ height: "auto" }}>
            <Row>
              <Col xs="12" className="add-quest-title title-highlights">
                Question Video
              </Col>
              <MediaContainer
                data={questVideo}
                type="videoRef"
                inputId="questVideo"
                enableEdit={enableEdit}
              />
            </Row>
          </Col>
        </Row>
        <Row style={{  height: "auto" }}>
          <Col className="add-answer-box" xs="11" md="8" lg="6" style={{ height: "auto" }}>
            <Row>
              <Col xs="12" className="add-quest-title title-highlights">
                Explanation Video
              </Col>
              <MediaContainer
                data={expVideo}
                type="videoRef"
                inputId="expVideo"
                enableEdit={enableEdit}
              />
            </Row>
          </Col>
        </Row>

        <Row >

          <Col
          xs="11" 
          md="8"
          lg="6"
            className="add-answer-box"
            style={{ height: "auto", paddingBottom: "2rem", display: "block"}} 
          >
            <Row xs="2">  <Col xs="10" className="title-highlights">
              References
            </Col>
           <Col xs="2"><Button
                onClick={enableEdit ? handleAddReference : null}
                variant="dark"
                className="add-reference-btn-editor" style={{fontWeight: "600", paddingTop: "1px"}}
              >  +
              </Button></Col> </Row>
          
            
          
          
            <Row>
              <hr
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "4rem",
                  left: "0",
                }}
              />
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "3rem",
                marginLeft: "1rem",
              }}
            >
              <MediaContainer
                data={refs}
                type="reference"
                inputId="reference"
                enableEdit={enableEdit}
              />
              {refInput &&
                Array.isArray(refInput) &&
                refInput.map((elem, index) => {
                  return (
                      <Col key={index} style={{paddingLeft:"0%"}}>
                        <Form.Control
                          type="text"
                          placeholder=" Reference goes here"
                          className="reference-input-editor"
                          id={index}
                          onChange={(e)=>handleNewReference(e, index)}
                          key={index * 0.1}
                        />
                      </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
        {modal && modal}
        <Row xs="12">
          <button
            type="submit"
            className="save-changes-btn"
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </Row>
      </Container>
   
  );
};

export default QuestionEditor;
