/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./History.css";
import { Container, Col, Row, Form } from "react-bootstrap";
import ExamService from "../../utils/ExamService.js";
import { useDispatch, useSelector } from "react-redux";
import { saveChosenExam } from "../../actions/exam.js";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

const History = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  //redux utilities
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { examData } = useSelector((state) => state.exam);
  //states
  const [exam, setExam] = useState();
  const [page, setPage] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [myQuestions, setMyQuestions] = useState([]);
  //================================================================================================//
  const getExamData = async () => {
    if (examData) {
      setExam(examData);
    } else {
      const examPlanB = await ExamService.populateExam(
        localStorage.getItem("exam")
      );
      setExam(examPlanB);
      dispatch(saveChosenExam(examPlanB));
    }
  };
  //=================================================================================================//
  useEffect(() => {
    getExamData();
  }, []);

  useEffect(() => {
    setLoading(false);
    questionFilter();
  }, [exam]);
  //===================================================================================================//
  let paper = [];
  const questionFilter = async () => {
    const response = await ExamService.getMyQuestions(user.id);
    if (response !== undefined) {
      setLoading(false);
    }
    setMyQuestions(response);

    if (exam) {
      for (let i = 0; i < exam.data.length; i++) {
        for (let j = 0; j < response.data.length; j++) {
          if (exam.data[i].id === response.data[j].questionId) {
            paper[j] = (
              <Row className="paper-view" key={response.data[j].questionId} style={{border:"2px grey solid"}}>
                <Row>
                  <Col
                    style={{
                      marginLeft: "-15%",
                      marginBottom: "1rem",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                  >
                    Question: {j + 1}
                  </Col>
                </Row>
                <Row className ="inner-template" style={{width: "100%"}}>
                  <Row
                    className="question-box"
                    style={{
                      height: "auto",
                      flewDirection: "column",
                      padding: ".5rem",
                    }}
                  >
                    <Col>
                      {/* <hr
                        style={{ width: "110%", marginLeft: "-2rem" }}
                      ></hr> */}
                      <Row
                        className="question-content"
                        style={{
                          maxWidth: "450px",
                          height: "auto",
                          flewDirection: "column",
                        }}
                      >
                        {exam.data[i].text}
                      </Row>
                    </Col>
                  </Row>
                  {exam.data[i].questionImgs &&
                    exam.data[i].questionImgs.map((img) => {
                      if(img){
                        return (
                        <Row className="image-row" key={img}>
                          <Col className="image-col">
                            <img
                              className="images"
                              src={img}
                              alt="chart"
                              style={{
                                width: "100%",
                                // height: "300px",
                                // padding: "1rem",
                                // paddingLeft: "45%",
                                marginLeft: "-0.5rem"
                              }}
                            />
                          </Col>
                        </Row>
                      );}
                      
                    })}
                  {exam.data[i].questionVideo && (
                    <Row className="image-row">
                      <Col
                        className="image-col"
                        style={{ marginLeft: "10%", marginBottom: "2rem" }}
                      >
                        <ReactPlayer
                          url={exam.data[i].questionVideo}
                          controls
                          muted
                          width="100%"
                          height="100%"
                        />
                      </Col>
                    </Row>
                  )}

                  <fieldset>
                    <Form.Group className="answers-group">
                      <Row style={{ width: "100%", marginTop: "2rem" }}>
                        <Form.Check
                          as="textarea"
                          rows={2}
                          style={{ height: "auto" }}
                          custom
                          type="radio"
                          label={exam.data[i].answer[0].option1}
                          name={"choice" + i}
                          id={"choice" + i + 1}
                          className={
                            (response.data[j].answer.choice === "choice1" &&
                              response.data[j].answer.value === "true") ||
                            exam.data[i].answer[0].flag1 === true
                              ? "answer-choises correct"
                              : response.data[j].answer.choice === "choice1" &&
                                response.data[j].answer.value === "false"
                              ? "answer-choises wrong"
                              : "answer-choises"
                          }
                          disabled={true}
                        />

                        <Form.Check
                          as="textarea"
                          rows={2}
                          style={{ height: "auto"}}
                          custom
                          type="radio"
                          label={exam.data[i].answer[1].option2}
                          name={"choice" + i}
                          id={"choice" + i + 2}
                          className={
                            (response.data[j].answer.choice === "choice2" &&
                              response.data[j].answer.value === "true") ||
                            exam.data[i].answer[1].flag2 === true
                              ? "answer-choises correct"
                              : response.data[j].answer.choice === "choice2" &&
                                response.data[j].answer.value === "false"
                              ? "answer-choises wrong"
                              : "answer-choises"
                          }
                          disabled={true}
                        />

                        <Form.Check
                          as="textarea"
                          rows={2}
                          style={{ height: "auto"}}
                          custom
                          type="radio"
                          label={exam.data[i].answer[2].option3}
                          name={"choice" + i}
                          id={"choice" + i + 3}
                          className={
                            (response.data[j].answer.choice === "choice3" &&
                              response.data[j].answer.value === "true") ||
                            exam.data[i].answer[2].flag3 === true
                              ? "answer-choises correct"
                              : response.data[j].answer.choice === "choice3" &&
                                response.data[j].answer.value === "false"
                              ? "answer-choises wrong"
                              : "answer-choises"
                          }
                          disabled={true}
                        />

                        <Form.Check
                          custom
                          as="textarea"
                          rows={2}
                          style={{ height: "auto" }}
                          type="radio"
                          label={exam.data[i].answer[3].option4}
                          name={"choice" + i}
                          id={"choice" + i + 4}
                          className={
                            (response.data[j].answer.choice === "choice4" &&
                              response.data[j].answer.value === "true") ||
                            exam.data[i].answer[3].flag4 === true
                              ? "answer-choises correct"
                              : response.data[j].answer.choice === "choice4" &&
                                response.data[j].answer.value === "false"
                              ? "answer-choises wrong"
                              : "answer-choises"
                          }
                          disabled={true}
                        />
                      </Row>
                    </Form.Group>
                  </fieldset>
                 
                  <Row style={{ width: "100%", marginLeft: "1.2rem", marginTop: "1.5rem" }}>
                    <Col className="explanation-title">Explanation</Col>
                  </Row>
                  <Row>
                    <Col className="explanation" style={{marginLeft:"0px"}} >{exam.data[i].explanation}</Col>
                  </Row>

                  {exam.data[i].explanationVideo && (
                    <Row className="image-row" >
                      <Col
                        className="image-col"
                        style={{ marginLeft: "10%", marginBottom: "1rem" }}
                      >
                        <ReactPlayer
                          url={exam.data[i].explanationVideo}
                          controls
                          muted
                          width="100%"
                          height="100%"
                        />
                      </Col>
                    </Row>
                  )}
                  {exam.data[i].references && exam.data[i].references.length > 0 && 
                  <Row style={{ width: "100%", marginLeft: "1.2rem" }}>
                    <Col className="explanation-title" style={{marginBottom: "0"}}>References</Col>
                  </Row>}
                  <Row style={{overflowX: "auto"}}>{exam.data[i].references && 
                    exam.data[i].references.map((ref) => {
                      return (
                        <Row
                        // style={{ marginLeft: "-13%" }}
                          key={i}
                          style={{
                            width:"100%"
                          }}
                        >
                          <Col className="explanation">
                            <Link
                              target="_blank"
                              onClick={() => {
                                window.open(ref.ref);
                              }}
                            >
                              {ref.ref}
                            </Link>
                          </Col>
                        </Row>
                      );
                    })}
                    </Row>                 
                </Row>
                
              </Row>
            );
          }
        }
      }
      setPage(paper);
    }
  };

  return (
    <Container className="history-container">
      <Row className="paper-view-container">
        <Row className="history-page-head">
          All previously answered Questions
        </Row>
        {!loading && page}
      </Row>
    </Container>
  );
};

export default History;
