import React,{useState,useEffect} from 'react'
import {Form } from 'react-bootstrap'
import "./List.css"

const List = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [options , setOptions]=useState([]);
  const [conditionOptions , setConditionOptions]=useState( 
  );
  let listArray=[];
  const fillList = ()=>{
    for(let i=1; i <= props.listLength; i++){
      listArray.push(<option key={i}>{i}</option>)
    }
    setOptions(listArray)
  }

  const fillListOfConditions = ()=>{
    setConditionOptions([
      <option value={props.value0} key={props.value0}>{props.text0}</option>,
      props.value1 &&  <option value={props.value1} key={props.value1}>{props.text1}</option>, 
      props.value2 &&  <option value={props.value2} key={props.value2}>{props.text2}</option>, 
      props.value3 &&   <option value={props.value3} key={props.value3}>{props.text3}</option>, 
      props.value4 &&  <option value={props.value4} key={props.value4}>{props.text4}</option>])
  }
  useEffect(() => {
   fillList();
   fillListOfConditions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  

 


    return (
        <Form  className="list-form">
  <Form.Group controlId="exampleForm.SelectCustom">
    
    <Form.Control value={props.value} className="list-form" as="select" custom onChange={props.onChange} >
   {conditionOptions && conditionOptions}
      {options && options}
    </Form.Control>
  </Form.Group>
</Form>
    )
}

export default List
